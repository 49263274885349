import { createContext, useContext, useState } from "react";

// Create the context
const ReferenceContext = createContext(undefined);

// Create a custom hook to use the context
export function useReferenceContext() {
    const context = useContext(ReferenceContext);
    if (context === undefined) {
        throw new Error("useReferenceContext must be used within a ReferenceProvider");
    }
    return context;
}

// Create the provider component
export function ReferenceProvider({ children }) {
    const [referencesList, setReferencesList] = useState([]);
    // const [golbalProjectId, setGolbalProjectId] = useState();

    const [miroValue, setMiroValue] = useState("");

    const [miroLink, setMiroLink] = useState("");
    const [wireframeId, setWireframeId] = useState(0);

    const [excalidrawLoading, setExcalidrawLoading] = useState(true);
    return (
        <ReferenceContext.Provider value={{
            referencesList,
            setReferencesList,
            miroValue,
            setMiroValue,
            miroLink,
            setMiroLink,
            excalidrawLoading,
            setExcalidrawLoading,
            wireframeId,
            setWireframeId
        }}>
            {children}
        </ReferenceContext.Provider>
    );
}
