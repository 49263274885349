import React from "react";
import { useTranslation } from "react-i18next";

import "../../views/Search/search.scss";
import noImageFound from "../../assets/icons/no-image-found.jpg";
import { useAuthContext } from "../../context/useAuthContext";
import { getCategoryLabel } from "../../helpers/labels";
import { useFilterContext } from "../../context/useFilterContext";
import { getCompanyName } from "../../helpers/search";

const Card = ({ data, handleViewDetails, isDebug }) => {
  const { t, i18n } = useTranslation();
  const { permissions } = useAuthContext();
  const { filterTags } = useFilterContext();


  // const textExtraction = data.text_extraction || "No Extraction found for the image";

  const currentLanguage = i18n.language;
  return (
    <div className="max-w-300 card">
      <div className="w-100 card_body">
        <div
          style={{
            maxWidth: "280px",
            height: "242px",
          }}
        >
          <img
            loading="lazy"
            className="p-2 search-images"
            alt="search-result-img"
            src={data?.image_url}
            onError={(e) => {
              e.target.src = noImageFound;
            }}
            title={(data?.text_extraction||t('no_text'))}
          />

        </div>
        <div className="d-flex m-2 mx-4 ctr">
          <div className={`d-flex justify-content-center align-items-center px-1 fs-12 bg-light-blue ${!isDebug?'rounded':'max-w-100 rounded-start'} w-100 text-center text-white py-1`}>
            <div>
              {data?.ctr?.length &&
                data?.ctr[0] !== null &&
                data?.ctr[1] !== null ? (
                <div>
                  <span>
                    CTR:{" "}
                    {data?.ctr[0].toFixed(3) === data?.ctr[1].toFixed(3)
                      ? `${(data.ctr[0] * 100).toFixed(2)}%`
                      : data.ctr[0].toFixed(2) === data.ctr[1].toFixed(2)
                        ? `${(data.ctr[0] * 100).toFixed(2)}%`
                        : `${(data.ctr[0] * 100).toFixed(2)}% ~ ${(
                          data.ctr[1] * 100
                        ).toFixed(2)}%`}
                  </span>
                </div>
              ) : (
                <span className="px-2 mt-1 text-white ctrspan">CTR: N/A</span>
              )}
            </div>
          </div>
          {isDebug ? (
            <div className="d-flex justify-content-center align-items-center fs-12 max-w-156 w-100 text-center py-1 rounded-end border-light-blue text-light-blue px-1">
            {t("matchScore")}:{" "}
            {data?.score
              ? (data?.score?.toFixed(2) * 100)?.toFixed(2) + "%"
              : "N/A"}
            </div>
          ):<></>}

        </div>

        {isDebug ? (
          <p
            className="pb-0 px-2 text-truncate-3"
            style={{
              color: "#707070",
              fontSize: "14px",

              overflow: "hidden",
            }}
            title={
              currentLanguage === "ja"
                ? data?.image_description_ja
                : data?.image_description
            }
          >
            {currentLanguage === "ja"
              ? data?.image_description_ja
              : data?.image_description}
          </p>):<></>}
        <div className="d-flex flex-column justify-content-start align-items-start mx-2">
          <div className="filter-label text-wrap">
            {t("imageType")}:{" "}
            {data?.image_type.map((a, index) => (
              <span key={index}>
                {t(getCategoryLabel("image_type", a, filterTags))}
              </span>
            ))}
          </div>
          <div className="filter-label text-wrap">
            {t("companyName")}: {getCompanyName(data.site_id, permissions)}
          </div>
        </div>

        <div className="d-flex justify-content-between align-items-center gap-2 flex-wrap">
          <div className="d-flex flex-wrap gap-1 w-100">
            {(currentLanguage === "en"
              ? data?.image_en_tags
              : data?.image_ja_tags
            )
              ?.slice(0, 6)
              ?.map((b, i) => {
                return (
                  <button
                    key={i}
                    className="border-grey w-100 text-overflow rounded-pill bg-transparent tag-button__search fs-12 m-1"
                    style={{
                      padding: "0px 8px",
                      color: "#707070",
                      position: "relative",
                      maxWidth: "80px",
                    }}
                    title={b}
                  >
                    {b}
                  </button>
                );
              })}
          </div>
        </div>
      </div>
      <div className="d-flex p-2 py-3 w-100">
        <button
          className="rounded-pill border-0 w-100 p-2 detailsbtn"
          onClick={() => handleViewDetails(data)}
        >
          {t("viewDetails")}
          <span
            style={{
              position: "absolute",
              right: "20px",
            }}
          >
            {">"}
          </span>
        </button>
      </div>
    </div>
  );
};

export default Card;