import React, { useEffect, useRef, useState } from "react";
import "./reference.scss";
import ArrowBack from "../../assets/newicons/arrow_back.svg";
import Settings from "../../assets/newicons/settings.svg";
import Chate from "../../assets/newicons/forum.svg";
import ReferenceComp from "./component/ReferenceComp";
import Draft from "./component/Draft";
import CreateProjectForm from "./component/createProjectForm";
import ChateBoat from "./component/ChateBoat";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { imageGet, newDelete, newGet, newPut } from "../../API/axios";
import { showAlert, showConfirmationDialog } from "./component/AlertService";
import { format } from "date-fns";
import CreateProjectFormLoader from "./skeletonLoaders/CreateProjectFormLoader";
import ProjectStages from "./component/ProjectStages";
import { FormControl, MenuItem, Select, Tooltip } from "@mui/material";
import { useAuthContext } from "../../context/useAuthContext";
import { useTranslation } from "react-i18next";
import { Excalidraw, MainMenu } from "@excalidraw/excalidraw";
import { useReferenceContext } from "../../context/useReferenceContext";

const useDebouncedValue = (inputValue, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(inputValue);


  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(inputValue);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue, delay]);

  return debouncedValue;
};

const Reference = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [isOpenChat, setIsOpenChat] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refListLoader, setRefListLoader] = useState(false);
  const location = useLocation();
  const { createdProjectId, siteId } = location.state || {};
  const [channelId, setChannelId] = useState(siteId);
  const [userList, setUserList] = useState([]);
  const [clientUserList, setClientUserList] = useState([]);
  const [clientManager, setClientManager] = useState([]);
  const allUsers = [...userList, ...clientUserList]
  const [projectDetails, setProjectDetails] = useState();
  const [referenceList, setReferenceList] = useState();
  const [assignee, setAssignee] = useState();
  const [internalApprover, setInternalApprover] = useState();
  const [clientApprover, setClientApprover] = useState();
  const [watchListList, setWatchListList] = useState();
  const [dueDate, setDueDate] = useState("");
  const [requiredSheet, setRequiredSheet] = useState();
  const [projectId, setProjectId] = useState();
  const [channelName, setChannelName] = useState();
  const [internalApprovalMsg, setInternalApprovalMsg] = useState("");
  const [projectStatus, setProjectStatus] = useState();
  const [isCreateDisable, setIsCreateDisable] = useState(false);

  const [inputProjectName, setInputProjectName] = useState("");
  const debouncedProjectName = useDebouncedValue(inputProjectName, 1000);

  const { hasPermission, userSettings } = useAuthContext();
  const [excalidrawData, setExcalidrawData] = useState(null);
  const [excalidrawAPI, setExcalidrawAPI] = useState(null);
  const [designDetails, setdesignDetails] = useState([]);
  const [excalidrawLoading, setExcalidrawLoading] = useState(true);
  const [isApprovalBtnDisable, setIsApprovalBtnDisable] = useState(true);
  const { referencesList, setReferencesList, setMiroValue, setMiroLink, setWireframeId } = useReferenceContext();
  const [checked, setChecked] = useState(false);
  const [projectName, setProjectName] = useState();

  const resolvablePromise = () => {
    let resolve;
    let reject;
    const promise = new Promise((_resolve, _reject) => {
      resolve = _resolve;
      reject = _reject;
    });
    promise.resolve = resolve;
    promise.reject = reject;
    return promise;
  }

  const initialStatePromiseRef = useRef({ promise: resolvablePromise() });
  if (!initialStatePromiseRef?.current?.promise) {
    initialStatePromiseRef.current.promise = resolvablePromise();

  }


  useEffect(() => {
    const updateData = async () => {
      setExcalidrawLoading(true);
      try {
        const files = excalidrawData.files || {};
        await Promise.all(
          Object.entries(files).map(async ([key, file]) => {
            if (!file.dataURL.startsWith('data:')) {
              const response = await imageGet('download_image', { image_url: file.dataURL });
              const base64String = await blobToBase64(response);
              excalidrawAPI.addFiles([{ ...file, dataURL: base64String }]);
              return [key, { ...file, dataURL: base64String }];

            }
            return [key, file];
          })
        );

        const updatedData = {
          elements: excalidrawData?.elements,
          appState: {
            ...(excalidrawData.appState || {})
          },
        };
        initialStatePromiseRef.current.promise.resolve(updatedData);

        excalidrawAPI.updateScene(updatedData);
        excalidrawAPI.scrollToContent();
        setTimeout((res) => {
          setExcalidrawLoading(false);
        }, 1000)
      } catch (error) {
        setExcalidrawLoading(false);
        console.error('Error updating Excalidraw data:', error);
      }
    };
    if (excalidrawData && excalidrawAPI) {
      updateData();
    } else {
      // if (excalidrawAPI && !excalidrawData) {
      //   setExcalidrawLoading(false);
      // }
      initialStatePromiseRef.current.promise.resolve({ appState: { viewModeEnabled: true } });
      // setExcalidrawLoading(false);
    }
  }, [excalidrawData, excalidrawAPI]);

  const [projectFormValue, setProjectFormValue] = useState({
    project_name: "",
    project_description: "",
    channel: "",
    assign_to: [],
    internal_approver: [],
    client_approver: [],
    watch_list: [],
    start_date: "",
    due_date: "",
    required_sheet: '',
  });

  const [oldFormValue, setOldFormValue] = useState({
    project_name: "",
    project_description: "",
    channel: "",
    assign_to: [],
    internal_approver: [],
    client_approver: [],
    watch_list: [],
    start_date: "",
    due_date: "",
    required_sheet: '',
  });

  useEffect(() => {
    if (projectFormValue.project_name.length < 3 || (projectFormValue.project_name === oldFormValue.project_name)) {
      return
    } else {
      checkProjectName(inputProjectName)
    }
  }, [debouncedProjectName])

  useEffect(() => {
    const modalElement = document.getElementById('exampleModal');
    let modalInstance = null;

    if (modalElement) {
      modalInstance = new window.bootstrap.Modal(modalElement);
    }

    const handleModalClose = () => {
      // console.log('Route changed, closing the modal if it is open.');

      if (modalInstance && modalInstance._isShown) {
        modalInstance.hide();
      }
    };

    handleModalClose();

    return () => {
      handleModalClose();
    };
  }, [location]);



  // Method to get the data of designs

  const getDesigns = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const projectIdFromUrl = urlParams.get('project_id');
    try {
      const data = await newGet(`design/getDesign?project_id=${createdProjectId || parseInt(projectIdFromUrl)}`).then((data) => {
        if (data && data.status_code == 200) {
          data !== undefined && setdesignDetails(data?.result);
        }
      })
    }
    catch (error) {
      console.log(error);
    }
  }

  // Remove the design version
  const removeDesignVersion = async (id) => {
    try {
      const data = await newDelete(`design/deleteDesign?design_id=${id}`);
      if (data.status_code === 200) {
        getDesigns();
        // showAlert("Design deleted successfully", "success")
        //    closeModal();
      }
    }
    catch (error) {
      console.log(error)
    }
  }

  // Method to handle "Submit for client approval" button
  const onHandleClientApproval = async () => {
    let formData = {}
    if (designDetails.length > 0) {
      const designIdArray = designDetails.map((design) => design.design_id).join(',');
      formData = {
        design_id: `${designIdArray}`,
        stage_id: 8,
        project_id: createdProjectId,
        "message": internalApprovalMsg
      }
    }

    try {
      await newPut("design/updateDesignStage", formData).then((response) => {
        if (response.status_code == 200) {
          setIsApprovalBtnDisable(true);
          const modalElement = document.getElementById('exampleModalSubmit');
          const modalInstance = window.bootstrap.Modal.getInstance(modalElement);
          modalInstance.hide();
        }
      })
    }
    catch (error) {
      console.log(error)
    }

  }
  const formatUserArray = (users, list) => {
    if (users) {
      const idMatches = users.match(/id:([a-zA-Z0-9-]+)/g);
      if (idMatches) {
        const ids = idMatches.map((idStr) => idStr.split(":")[1]);
        const selectedUsers = list?.filter((user) =>
          ids.includes(user.account_login_id)
        );
        return selectedUsers;
      } else {
        console.error("No valid ids found in the input string.");
        return [];
      }

    } else {
      return null;
    }
  };

  const [errors, setErrors] = useState({
    project_name: "",
    project_description: "",
    channel: "",
    assign_to: "",
    internal_approver: "",
    // client_approver: "",
    watch_list: "",
    start_date: "",
    due_date: "",
    required_sheet: "",
  });

  const getProjectDetail = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const projectIdFromUrl = urlParams.get('project_id');
    const storedSiteId = localStorage?.getItem('siteId');
    if ((createdProjectId || projectIdFromUrl) && (siteId || storedSiteId)) {
      try {
        setLoading(true);
        const data = await newGet(
          `project/getProjectDetails?project_id=${createdProjectId || parseInt(projectIdFromUrl)}&site_id=${siteId || parseInt(storedSiteId)}`
        );
        setProjectDetails(data?.result[0])
        const status = data?.result[0].status
        setProjectStatus(parseInt(Object.keys(status)[0], 10))
        const assigneeList = makeUserArray(data?.result[0]?.assign_to_user_name)
        setAssignee(assigneeList)
        const internalApproverList = makeUserArray(data?.result[0]?.internal_approver_username)
        setInternalApprover(internalApproverList)
        const clientApproverList = makeUserArray(data?.result[0]?.client_approver_username)
        setClientApprover(clientApproverList)
        const watchListUsers = makeUserArray(data?.result[0]?.watchlist)
        setWatchListList(watchListUsers)
        const date = formatCreatedTimestamp(data?.result[0]?.due_date)
        setDueDate(date)
        setRequiredSheet(data?.result[0]?.required_sheet)
        setChannelName((data?.result[0]?.channel_name[0]?.site_name))
        setProjectName(data?.result[0]?.project_name)

        localStorage.setItem('siteId', data?.result[0]?.channel_name[0]?.site_id)

        setLoading(false);
      } catch (error) {
        console.log("error:???????", error);
        setLoading(false);
      }
    }
  };
  const blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
        const base64Image = reader.result;
        const img = new Image();
        img.src = base64Image;
        img.onload = () => {
          // setImageHeight(img.height)
        };
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const getReferences = async () => {
    try {
      setRefListLoader(true);

      if (!createdProjectId) {
        return;
      }

      setLoading(true)
      await newGet(
        `reference/fetchReferences?project_id=${createdProjectId}`
      ).then(async (data) => {
        if (data && data.status_code == 200) {
          setReferenceList(data?.result.references);
          setReferencesList(data?.result);
          setMiroValue(data?.result.miro_link);
          setMiroLink(data?.result.miro_link);
          setWireframeId(data?.result?.wireframe_id);
          if (data?.result?.annotation_json) {

            const parsedData = JSON.parse(data.result.annotation_json);
            const fileKeys = Object.keys(parsedData.files);

            for (const key of fileKeys) {
              const file = parsedData.files[key];
              if (!file.dataURL.startsWith('data:')) {
                const response = await imageGet("download_image", { image_url: file.dataURL });
                const base64String = await blobToBase64(response);
                if (excalidrawData) {
                  excalidrawAPI.addFiles([{ ...file, dataURL: base64String }]);
                }
                // file.dataURL = base64String;
              }
            }

            const updatedData = {
              ...parsedData,
              appState: {
                ...(parsedData.appState || {}),
                viewModeEnabled: true,
              }
            };
            if (excalidrawAPI) {
              excalidrawAPI?.updateScene(updatedData);
              excalidrawAPI.scrollToContent();
              setExcalidrawLoading(false);
            } else {
              setExcalidrawData(updatedData);
            }
            setLoading(false)
          } else {
            setExcalidrawLoading(false);
          }
        }
      });

      setRefListLoader(false);
    } catch (error) {
      setLoading(false)
      console.log("error:???????", error);
      setRefListLoader(false);
    }
  }


  const checkProjectName = async (value) => {
    const specialCharsPattern = /[^a-zA-Z0-9 _]/g;
    const testValue = specialCharsPattern.test(value)
    if (!testValue) {
      try {
        await newGet(`project/checkProjectName?project_name=${value}&site_id=${projectFormValue?.channel?.site_id}`).then((data) => {
          if (data && data.status_code == 200) {

            if (data.result[0]?.count_project_name > 0) {
              setErrors({ ...errors, project_name: "Project name already exist !!!" })
              setIsCreateDisable(true);
            } else {
              setErrors({ ...errors, project_name: "Project name is valid" })
              setIsCreateDisable(false);
            }
          }
        });
      } catch (error) {
        console.log("error:???????", error);
        setLoading(false);
      }
    } else {
      setErrors({ ...errors, project_name: "special characters are not allowed !!!" })
      setIsCreateDisable(true);
    }

  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors({ ...errors, [name]: "" });

    if (name === "required_sheet") {
      // Only allow digits (no strings)
      if (/^\d*$/.test(value.trim())) {
        // Check if the value is within the allowed range
        if (value.trim() === "" || parseInt(value.trim()) <= 50) {
          setProjectFormValue({ ...projectFormValue, [name]: value.trim() });
        } else {
          setErrors({ ...errors, [name]: "Maximum sheet allowed is 50!" });
        }
      }
      // If it's not a number, do nothing (no need to update the form value)
    } else {
      setProjectFormValue({ ...projectFormValue, [name]: value });
    }
  };

  const handleDateChange = (date, name) => {
    const formattedDate = date ? format(date, "MM-dd-yyyy") : null;
    setProjectFormValue({ ...projectFormValue, [name]: formattedDate });
    setErrors({ ...errors, [name]: "" });
  };

  const handleChannelChange = (site) => {
    setProjectFormValue({ ...projectFormValue, channel: site });
    setErrors({ ...errors, channel: "" });
  };

  const handleInternalApprovalMsg = (e) => {
    setInternalApprovalMsg(e.target.value);
  }

  const handlemultipleUsers = (e, options, fieldName) => {
    let newFormValue = { ...projectFormValue, [fieldName]: options };
    let newErrors = { ...errors, [fieldName]: "" };

    if (['assign_to', 'internal_approver', 'client_approver'].includes(fieldName)) {
      const combinedWatchList = [
        ...new Set([
          ...newFormValue.assign_to,
          ...newFormValue.internal_approver,
          ...newFormValue.client_approver,
        ])
      ];
      const watchListUser = projectFormValue?.watch_list?.filter(user =>
        !combinedWatchList?.some(combined => combined.user_id === user.user_id)
      );
      newFormValue = { ...newFormValue, watch_list: watchListUser };
    }
    setProjectFormValue(newFormValue);
    setErrors(newErrors);
  };

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const data = await newGet(`project/usersByRole?role_name=channel`);
      const dataClient = await newGet(`project/usersByRole?role_name=client`);
      const allUsers = [...data?.result, ...dataClient?.result]
      setUserList(data?.result || []);
      setClientUserList(dataClient?.result || []);
      setClientManager(dataClient?.result?.filter((user) => user.role_name === "CLIENT_MANAGER"));
      const assignTo =
        formatUserArray(projectDetails?.assign_to_user_name || "", data?.result) ||
        [];
      const internalApprover =
        formatUserArray(
          projectDetails?.internal_approver_username || "",
          data?.result
        ) || [];
      const clientApprover =
        formatUserArray(
          projectDetails?.client_approver_username || "",
          dataClient?.result
        ) || [];

      const watchList =
        formatUserArray(
          projectDetails?.watchlist || "",
          allUsers
        ) || [];


      setProjectFormValue({
        project_name: projectDetails.project_name || "",
        project_description: projectDetails.project_desc || "",
        channel: projectDetails.channel_name[0],
        assign_to: assignTo,
        internal_approver: internalApprover,
        client_approver: clientApprover,
        watch_list: watchList,
        start_date: formatDate(projectDetails.start_date || ""),
        due_date: formatDate(projectDetails.due_date || ""),
        required_sheet: projectDetails?.required_sheet ? projectDetails?.required_sheet : 0,
      });

      setOldFormValue({
        project_name: projectDetails.project_name || "",
        project_description: projectDetails.project_desc || "",
        channel: projectDetails.channel_name[0],
        assign_to: assignTo,
        internal_approver: internalApprover,
        client_approver: clientApprover,
        watch_list: watchList,
        start_date: formatDate(projectDetails.start_date || ""),
        due_date: formatDate(projectDetails.due_date || ""),
        required_sheet: projectDetails?.required_sheet ? projectDetails?.required_sheet : 0,
      });

      setProjectId(projectDetails.project_id);
      if (clientApprover?.length > 0) {
        setChecked(true);
      }
      setLoading(false);

      // getProjectDetail(data?.result || []);
    } catch (error) {
      console.log("error:???????", error);
      setLoading(false);
    }
  };

  const formatDate = (date1) => {

    const datePart = date1?.slice(0, 10);
    const [year, month, day] = datePart?.split("-");
    const formattedDate = `${month}-${day}-${year}`;
    return formattedDate;

  };

  const formatDueDate = (date1) => {
    const datePart = date1?.slice(0, 10);
    const [year, month, day] = datePart?.split("-");
    const formattedDate = `${year}/${month}/${day}`;
    return formattedDate;
  };

  const formatCreatedTimestamp = (timestamp) => {
    if (timestamp) {
      const options = { year: 'numeric', month: 'short', day: '2-digit' };
      const date = new Date(timestamp);
      return date.toLocaleDateString('en-US', options);
    } else {
      return "Not specified"
    }
  };


  // const handleClientSubmit = async () => {
  //   try {
  //     const data = await newPut(`design/shareWithClient`, {
  //       project_id: createdProjectId
  //     });
  //     if (data.status_code == 200 || data.status_code == 201) {
  //       getDesigns();
  //       showAlert("Design shared successfully", "success")
  //     }
  //   } catch (err) {
  //     console.log('err: ', err)
  //   }
  // }

  const handleClientApproveReturn = async (status) => {
    // console.log("DesignDetails ", designDetails)
    //  console.log("pid ", createdProjectId)
    //  console.log("design_id ", design_id)
    //  console.log("version_number ", version_number)
    //  console.log("status ", status)
    try {
      const data = await newPut(`design/approveReturnDesign`, {
        project_id: createdProjectId,
        design_id: 189,
        version_number: 1,
        status: status
      });
      if (data.status_code == 200 || data.status_code == 201) {
        showAlert(`Design ${status} successfully`, "success");
        // showAlert(i18n.t(data?.message[0]?.property_message), 'success');

      }
    }
    catch (err) {
      console.log('err: ', err)
    }
  }


  const handleSubmit = async () => {
    const requiredFields = [
      { key: "project_name", message: "Project name is required." },
      {
        key: "project_description",
        message: "Project description is required.",
      },
      { key: "channel", message: "Channel name is required." },
      {
        key: "assign_to",
        message: "Assign to name is required.",
        isArray: true,
      },
      {
        key: "internal_approver",
        message: "Internal approver name is required.",
        isArray: true,
      },

      { key: "due_date", message: "Due date is required." },
      { key: 'required_sheet', message: 'No. of sheet is required.' },
    ];

    let formIsValid = true;
    const newErrors = { ...errors };

    requiredFields.forEach((field) => {
      if (
        field.isArray
          ? projectFormValue[field.key].length === 0
          : !projectFormValue[field.key]
      ) {
        newErrors[field.key] = field.message;
        formIsValid = false;
      } else if (field.key === 'required_sheet' && (!projectFormValue[field.key] || projectFormValue[field.key] == 0)) {
        newErrors[field.key] = projectFormValue[field.key] == 0 ? "Required sheet can not be zero" : field.message;
        formIsValid = false;
      } else {
        newErrors[field.key] = "";
      }
    });

    if (!formIsValid) {
      setErrors(newErrors);
      return;
    }

    const getChangedValues = (newForm, oldForm) => {
      let changedValues = {};

      Object.keys(newForm).forEach((key) => {
        if (JSON.stringify(newForm[key]) !== JSON.stringify(oldForm[key])) {
          changedValues[key] = newForm[key];
        }
      });

      return changedValues;
    };

    const changedFormData = getChangedValues(projectFormValue, oldFormValue);

    const formData = {
      project_id: projectId,
      site_id: projectFormValue?.channel?.site_id?.toString(),
      ...(changedFormData.project_name && { project_name: changedFormData.project_name }),
      ...(changedFormData.project_description && { project_desc: changedFormData.project_description }),
      // ...(changedFormData.channel && { site_id: (changedFormData.channel?.site_id).toString() }),
      ...(changedFormData.assign_to && {
        assign_to_user_id: changedFormData.assign_to
          ?.map((user) => user?.account_login_id)
          .join(",")
      }),
      ...(changedFormData.internal_approver && {
        internal_approver_user_id: changedFormData.internal_approver
          ?.map((user) => user?.account_login_id)
          .join(",")
      }),
      ...(changedFormData.client_approver && {
        client_approver_user_id: changedFormData.client_approver
          ?.map((user) => user?.account_login_id)
          .join(",")
      }),
      ...(changedFormData.watch_list && {
        watchlist: changedFormData.watch_list
          ?.map((user) => user?.account_login_id)
          .join(",")
      }),
      // start_date: projectFormValue?.start_date,
      ...(changedFormData.start_date && { start_date: projectFormValue?.start_date }),
      ...(changedFormData.due_date && { due_date: changedFormData.due_date }),
      ...(changedFormData.required_sheet && { required_sheet: parseInt(changedFormData.required_sheet) }),
    };


    try {
      if (!changedFormData.project_name && !changedFormData.project_description && !changedFormData.assign_to &&
        !changedFormData.internal_approver && !changedFormData.client_approver && !changedFormData.watch_list && !changedFormData.due_date && !changedFormData.required_sheet) {
        closeModal()
        return;
      }
      setLoading(true);
      const data = await newPut(`project`, formData);
      if (data.status_code == 200) {
        // showAlert("Project Updated successfully", "success");
        showAlert(i18n.t(data?.message[0]?.property_message), 'success');
        closeModal()
      }
      setLoading(false);
      getProjectDetail()
    } catch (error) {
      console.log("error:", error);
      setLoading(false);
    }
  };

  const closeModal = () => {
    const modalElement = document.getElementById('exampleModal');
    const modalInstance = window.bootstrap.Modal.getInstance(modalElement);
    modalInstance.hide();
  };
  const handleClearForm = () => {
    setProjectFormValue({
      project_name: '',
      project_description: '',
      channel: '',
      required_sheet: "",
      assign_to: [],
      internal_approver: [],
      client_approver: [],
      // watchlist: false,
      watch_list: [],
      start_date: '',
      due_date: '',
    })
    setErrors({
      project_name: '',
      project_description: '',
      channel: '',
      required_sheet: "",
      assign_to: "",
      internal_approver: "",
      client_approver: "",
      watch_list: "",
      start_date: '',
      due_date: '',
    })
    closeModal();
  };
  const handleGoBack = () => {
    navigate("/dashboard");
  };


  function makeUserArray(input) {
    if (input) {
      const objectsArray = input.split("},").map(item => {
        // Add back the closing curly brace that was removed during the split (if needed)
        item = item.trim().endsWith("}") ? item : item + "}";

        // Extract the id and username values
        const idMatch = item.match(/id:([a-f0-9\-]+)/);
        const usernameMatch = item.match(/username:([^,}]+)/);

        // Return the object in proper format
        return {
          id: idMatch ? idMatch[1] : null,
          username: usernameMatch ? usernameMatch[1].trim() : null
        };
      });
      return objectsArray
    }
    else {
      return
    }
  }

  const makeSiteObject = (str) => {
    if (!str) {
      return null;
    }
    const jsonString = str?.replace(/(\w+):([^,}]+)/g, (match, key, value) => {
      // If the value is a number, keep it as is; otherwise, wrap it in quotes
      const formattedValue = isNaN(value) ? `"${value.trim()}"` : value.trim();
      return `"${key}":${formattedValue}`;
    });

    return JSON.parse(jsonString);
  }

  const handelVariant = () => {
    // navigate('/newimage-annotation', {
    //   state: {
    //     selectedImages: "",
    //     createdProjectId: createdProjectId
    //   },
    // });
  };

  const handleStatusChange = (e) => {
    setProjectStatus(e.target.value);
  };

  const handleUpdateReference = async (ref) => {
    ref = { ...ref, image_url: ref.reference_image_url };
    navigate('/reference-details?project_id=' + createdProjectId, {
      state: {
        selectedImages: [ref],
        createdProjectId: createdProjectId,
        channelId: channelId
      },
    });

  }

  const hadleWorkSpace = (data) => {
    navigate('/reference-details?project_id=' + createdProjectId, {
      state: {
        createdProjectId: createdProjectId,
        channelId: channelId
      },
    });
  }

  const handleDeleteReference = async (ref) => {
    const confirmation = await showConfirmationDialog(
      `${t("projectDeleteMsg")}`,
      "",
      "warning",
      `${t("no")}`,
      `${t("yes")}`
    );
    try {
      if (confirmation) {
        const data = await newDelete(`reference/delete?reference_id=${ref.reference_id}`);
        if (data.status_code == 200) {
          // showAlert("Reference Deleted successfully", "success")
          showAlert(i18n.t(data?.message[0]?.property_message), 'success');
          getReferences();
        }
      }
    } catch (error) {
      console.log("error:???????", error);
    }

  }

  useEffect(() => {
    getReferences();
    const urlParams = new URLSearchParams(window.location.search);
    const projectIdFromUrl = urlParams.get('project_id');
    const storedSiteId = localStorage?.getItem('siteId');
    if ((createdProjectId || projectIdFromUrl) && (siteId || storedSiteId)) {
      getProjectDetail()
      getDesigns()
      if (requiredSheet === designDetails?.length) {
        setIsApprovalBtnDisable(false)
      }
      else {
        setIsApprovalBtnDisable(true)
      }
    } else {
      navigate("/dashboard")
    }
  }, []);


  return (
    <div>
      <div className="bg_color_gray">
        <div className="container-fluid p-0">
          <div className="d-flex justify-content-between flex-md-nowrap flex-wrap align-items-center p-xl-4 gap-lg-3 gap-md-2 gap-3 p-lg-4  py-sm-3 px-sm-3 p-2 py-3 bg_color_gray border-bottom">
            <div className="d-flex align-items-center gap-2">
              <div onClick={handleGoBack} className="cursor-pointer">
                <img src={ArrowBack} className="" alt="ArrowBack" />
              </div>
              <div className="header_line"></div>
              <div className="font-noto-sans micro_ad text-truncate">
                {/* {channelName || '---'} */}
                {projectName || '---'}
              </div>
              {!loading &&
                <div className="mb-1 header-selector">
                  <FormControl>
                    <div
                      className="rounded"
                      style={{ backgroundColor: "#666666", minWidth: "90px" }}
                    >
                      <Select
                        value={projectStatus}
                        name="channel"
                        onChange={handleStatusChange}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        className="w-100 border-none text-white font-noto-sans fs-14 fw-500"
                        disabled={userSettings?.permissions && !hasPermission(17)}
                      >
                        <MenuItem value={1}>{t("inProgress")}</MenuItem>
                        <MenuItem value={2}> {t("completed")}</MenuItem>
                        <MenuItem value={3}> {t("onHold")}</MenuItem>
                        <MenuItem value={4}> {t("cancel")}</MenuItem>
                        <MenuItem value={5}> {t("submitForClientApproval")}</MenuItem>
                      </Select>
                    </div>
                  </FormControl>
                </div>
              }
            </div>
            <div
              style={{ maxWidth: "650px" }}
              className="w-100 d-flex align-items-baseline justify-content-between gap-lg-3 gap-2"
            >
              <div className="d-flex flex-column align-items-center">
                <h6 className="font-noto-sans micro_ad mb-0">{t("approver")}</h6>
                <div className="d-flex gap-1  align-items-center">

                  <div className="d-flex align-items-center">
                    {((internalApprover !== undefined && internalApprover[0] !== null) && internalApprover?.map((name) => (
                      <Tooltip title={<div>
                        <span>{name?.username}</span>
                      </div>} slotProps={{ popper: { modifiers: [{ name: 'offset', options: { offset: [0, -12] } }] } }} placement="bottom" arrow>
                        <p key={name?.id} className="approver-icon mb-0">
                          <span>{name?.username?.slice(0, 2)?.toUpperCase()}</span>
                        </p>
                      </Tooltip>
                    ))) || ""}
                  </div>

                  <div className="header_line"></div>

                  <div className="d-flex align-items-center">

                    {((clientApprover !== undefined && clientApprover[0] !== null) && clientApprover?.map((name) => (
                      <Tooltip title={<div>
                        <span>{name?.username}</span>
                      </div>} slotProps={{ popper: { modifiers: [{ name: 'offset', options: { offset: [0, -12] } }] } }} placement="bottom" arrow>
                        <p key={name?.id} className="approver-icon mb-0">
                          <span>{name?.username?.slice(0, 2)?.toUpperCase()}</span>
                        </p>
                      </Tooltip>
                    ))) || ""}
                  </div>
                  {/* <button className="border-0 bg-transparent p-1">
                    <img src={blueUser} alt="user-icon" />
                  </button> */}
                </div>

              </div>

              <div className="d-flex flex-column align-items-center">
                <h6 className="font-noto-sans micro_ad mb-0">{t("assignee")}</h6>
                <div className="d-flex align-items-center">
                  {((assignee !== undefined && assignee[0] !== null) && assignee?.map((name) => (
                    <Tooltip title={<div>
                      <span>{name?.username}</span>
                    </div>} slotProps={{ popper: { modifiers: [{ name: 'offset', options: { offset: [0, -12] } }] } }} placement="bottom" arrow>
                      <p key={name?.id} className="approver-icon mb-0">
                        <span>{name?.username?.slice(0, 2)?.toUpperCase()}</span>
                      </p>
                    </Tooltip>
                  ))) || ""}
                </div>
              </div>

              <div className="d-flex flex-column align-items-center gap-1">
                <h6 className="font-noto-sans micro_ad mb-0">{t("DueDate")}</h6>
                <h6 className="font-noto-sans micro_ad mb-0">{dueDate || ""} </h6>
              </div>
              <div className="d-flex flex-column align-items-center gap-1">
                <h6 className="font-noto-sans micro_ad mb-0">{t("requiredSheet")}</h6>
                <h6 className="font-noto-sans micro_ad mb-0">{requiredSheet || ""}</h6>
              </div>
            </div>
            <div className="d-flex gap-lg-3 gap-0 align-items-center">

              {userSettings?.permissions && hasPermission(11) &&
                <button
                  onClick={() => fetchUsers()}
                  className="cursor_pointer border-0 bg-transparent"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  disabled={userSettings?.permissions && !hasPermission(11)}
                >
                  <img src={Settings} alt="settings" />
                </button>
              }
              {/* project detail modal */}

              <div className="modal fade font-noto-sans" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                  <div className="modal-content">
                    <div className="modal-header border-0 pb-0">
                      <h5 className="modal-title fw-bold" id="exampleModalLabel">{t("projectDetails")}</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClearForm}></button>
                    </div>
                    <CreateProjectForm
                      handleChange={handleChange}
                      handlemultipleUsers={handlemultipleUsers}
                      projectFormValue={projectFormValue}
                      setProjectFormValue={setProjectFormValue}
                      errors={errors}
                      userList={userList}
                      allUsers={allUsers}
                      clientManager={clientManager}
                      handleChannelChange={handleChannelChange}
                      loading={loading}
                      handleDateChange={handleDateChange}
                      setInputProjectName={setInputProjectName}
                      IsFormForUpadate={true}
                      checked={checked}
                      setChecked={setChecked}
                    />

                    {loading ? (
                      <div className="modal-footer border-0">
                        <CreateProjectFormLoader height={60} width={100} rounded={"rounded-25"} />
                        <CreateProjectFormLoader height={60} width={100} rounded={"rounded-25"} />
                      </div>
                    ) : (
                      <div className="modal-footer border-0">
                        <p type="button" className="cancel_modal_footer" data-bs-dismiss="modal" onClick={handleClearForm}>{t("cancel")}</p>
                        <button type="button" className="send_modal_footer" onClick={handleSubmit} disabled={isCreateDisable}>{t("save")}</button>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* end of project detail modal */}
              {/* {userSettings?.permissions && hasPermission(16) &&
                <button
                  className="header-submit rounded-pill text-nowrap"
                  // data-bs-toggle="modal"
                  // data-bs-target="#exampleModalSubmit"
                  disabled={!designDetails?.length > 0 || clientApprover === undefined}
                  onClick={handleClientSubmit}
                >
                  {t("submitForClientApproval")}
                </button>
              } */}


              {/* submit model */}

              <div
                className="modal fade"
                id="exampleModalSubmit"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog me-5 pt-5">
                  <div className="modal-content mt-3">
                    <div className="modal-header border-0">
                      <h5
                        className="font-noto-sans fw-bold fs-20 mb-0 text-black"
                        id="exampleModalLabel"
                      >
                        Internal approver
                      </h5>
                    </div>

                    <div className="modal-body font-noto-sans fw-500 pt-0 ">
                      <div className="d-flex align-items-center gap-3">
                        <div className="d-flex align-items-center gap-2 submit-client-approval rounded-pill p-1">
                          <p className="approver-icon mb-0">
                            <span>TY</span>
                          </p>
                          <span className="pe-2 font-noto-sans fw-bold fs-14">
                            Takashi Yamada
                          </span>
                        </div>
                        <div className="d-flex align-items-center gap-2 submit-client-approval rounded-pill p-1">
                          <p className="approver-icon mb-0">
                            <span>TY</span>
                          </p>
                          <span className="pe-2 font-noto-sans fw-bold fs-14">
                            Takashi Yamada
                          </span>
                        </div>
                      </div>
                      <div className="mt-3">
                        <form>
                          <div className="d-flex flex-column gap-2">
                            <label className=" font-noto-sans fw-bold fs-14 ms-0">
                              Message
                            </label>
                            <textarea
                              placeholder="For your approval"
                              className="submit-client-approval-textarea"
                              value={internalApprovalMsg}
                              onChange={handleInternalApprovalMsg}
                            ></textarea>
                          </div>
                        </form>
                      </div>
                    </div>

                    <div className="modal-footer mt-0 border-0">
                      <button
                        className="rounded-pill px-4 bg-transparent h-36 border-0 text-black font-noto-sans fw-bold fs-14 text-nowrap"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        Cancel
                      </button>
                      <button
                        className="rounded-pill px-4 bg-color-black h-36 border-black text-white font-noto-sans fw-500 fs-14"
                        // data-bs-toggle="modal"
                        data-bs-target="#exampleModalSubmit"
                        onClick={onHandleClientApproval}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* end of submit model */}

            </div>
          </div>
        </div>
        <div className="container-fluid px-md-5 px-sm-4 px-3 my-4">
          <div className="row justify-content-center">

            <div className="px-xl-5">
              <ProjectStages
                projectDetails={projectDetails}
                designDetails={designDetails}
              />

              <div className="p-lg-4 mt-3 p-md-3 p-3 bg-white bora_24 bg_white_padding">
                <div className="d-flex justify-content-between align-items-center cursor_pointer mb-1">
                  <div className="d-flex align-items-center gap-4">

                    <div className="text_formate">{t('wireframe')}</div>

                  </div>
                </div>
                <div style={{ maxHeight: "390px", overflowY: "auto", display: excalidrawLoading ? 'none' : 'block' }}>
                  <div className="d-flex flex-wrap align-items-center justify-content-center gap-3 hover-container" >
                    <div className="wireframe-transparent-layer"></div>
                    <div
                      title="Click to start design."
                      className='position-relative mt-4 reference-view-mode workspace-image'

                    // onClick={hadleWorkSpace}
                    >
                      <Excalidraw
                        excalidrawAPI={(api) => setExcalidrawAPI(api)}
                        initialData={initialStatePromiseRef.current.promise}>
                      </Excalidraw>


                    </div>
                    <button className="hover-button" onClick={hadleWorkSpace} >
                      {t("open")}
                    </button>
                  </div>

                </div>
                <div style={{ maxHeight: "390px", overflowY: "auto", display: !excalidrawLoading ? 'none' : 'block' }}>
                  <CreateProjectFormLoader height={"260px"} width={"100%"}  ></CreateProjectFormLoader>
                </div>

                <ReferenceComp
                  createdProjectId={createdProjectId}
                  referenceList={referenceList}
                  setReferenceList={setReferenceList}
                  channelId={channelId}
                />
              </div>

              <div>
                <div className="bora_24 bg-white mt-3">
                  <div className="border_bootom">
                    <Draft
                      designDetails={designDetails}
                      setdesignDetails={setdesignDetails}
                      projectDetails={projectDetails}
                      assignee={assignee}
                      internalApprover={internalApprover}
                      clientApprover={clientApprover}
                      removeDesignVersion={removeDesignVersion}

                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div
          typeof="button"
          className="chate_box cursor_pointer"
          onClick={() => setIsOpenChat(!isOpenChat)}
        >
          <div className="d-flex justify-content-center align-items-center w-100 h-100">
            <img src={Chate} className="" alt="Chate" />
          </div>
        </div>
        {isOpenChat && (
          <div>
            <ChateBoat isOpenChat={isOpenChat} setIsOpenChat={setIsOpenChat} ProjectId={createdProjectId} designDetails={designDetails} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Reference;
