import React from "react";
import checkCircle from "../../../assets/newicons/check_circle_new.svg";
import grayRing from "../../../assets/newicons/Ellipse 632.svg";
import redInfo from "../../../assets/newicons/info.svg";
import warning from "../../../assets/newicons/warning.svg";
import normalBlack from "../../../assets/newicons/Incomplete--normal.svg";
import { useTranslation } from "react-i18next";
const ProjectStages = ({projectDetails, designDetails}) => {

  const { t, i18n } = useTranslation();
  return (
    <div className="p-lg-4 mt-3 p-md-3 p-3 bg-white bora_24 bg_white_padding">
      <h6 className="mb-0 text_formate pb-lg-4 pb-md-3 pb-2">{t("projectStages")}</h6>
      <div className="project-stepper">
        <div>
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              aria-valuenow="70"
              aria-valuemin="0"
              aria-valuemax="100"
              // style={{ width: `${(designDetails?.length/ projectDetails?.required_sheet)*100}%`, backgroundColor: "black" }}
            ></div>
          </div>
          <div className="d-flex align-items-center gap-1 mt-3 ps-1">
            <img src={normalBlack} alt="normalBlack" />
            <p className="font-noto-sans fs-14 fw-bold mb-0">{t("design")}</p>
          </div>
          <p className="fs-16 font-noto-sans fw-500 mt-2 ms-4">
            <span className="fw-bold">{designDetails?.length}/{projectDetails?.required_sheet} </span>{t("uploaded")}
          </p>
        </div>
        <div>
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              aria-valuenow="70"
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: "0%", backgroundColor: "black" }}
            ></div>
          </div>

          {/* <div className="d-flex align-items-center gap-1 mt-3 ps-1">
            <img src={warning} alt="normalBlack" />
            <p className="font-noto-sans  fw-500 fs-14 mb-0">
              Internal Approval
            </p>
          </div> */}

          {/* <div className="d-flex align-items-center gap-1 mt-2 ps-4 position-relative">
            <p className="font-noto-sans text-danger fw-500 fs-14 mb-0">
              Internal Approval
            </p>
            <div className="info-error">
              <div className="info-error-hover">
                <img src={redInfo} alt="normalBlack" />
              </div>
              <div className="info-error-hover-content shadow">
                <p className="font-noto-sans text-black fw-500 fs-14 mb-0">
                  Required sheet updated from 4 to 5
                </p>
              </div>

              <div className="info-error-hover-content shadow">
                <div className="d-flex gap-2 align-items-center justify-content-between w-100">
                  <div className="d-flex gap-2 align-items-center ">
                    <p className="approver-icon mb-0">
                      <span>TY</span>
                    </p>
                    <span className="font-noto-sans text-black fw-500 fs-14">
                      Takashi Yamada
                    </span>
                  </div>
                  <div className="whait_red_text">REVISION REQUESTED</div>
                </div>
                <div className="d-flex gap-2 align-items-center mt-3 justify-content-between w-100">
                  <div className="d-flex gap-2 align-items-center ">
                    <p className="approver-icon mb-0">
                      <span>TY</span>
                    </p>
                    <span className="font-noto-sans text-black fw-500 fs-14">
                      Approver 2
                    </span>
                  </div>
                  <div className="whait_green_text">APPROVED</div>
                </div>
              </div>

            </div>
          </div> */}
          
          <div className="d-flex align-items-center gap-1 mt-3 ps-1">
            <img src={grayRing} alt="normalBlack" />
            <p className="font-noto-sans gray-text-color fw-500 fs-14 mb-0">
            {t("internalapproval")}
            </p>
          </div>

        </div>
        <div>
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              aria-valuenow="70"
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: "0%", backgroundColor: "black" }}
            ></div>
          </div>
          <div className="d-flex align-items-center gap-1 mt-3 ps-1">
            <img src={grayRing} alt="normalBlack" />
            <p className="font-noto-sans gray-text-color fw-500 fs-14 mb-0">
            {t("clientapproval")}
            </p>
          </div>
        </div>
      </div>
      {/* <p className="light-red-bg py-2 mt-2 px-3 rounded font-noto-sans fs-14 fw-500">
        The submitted design(s) require revision. Please make the necessary
        updates and resubmit for approval.
      </p> */}
    </div>
  );
};
export default ProjectStages;







