import React, { useEffect, useRef, useState } from 'react';
import "../reference.scss";
import UploadDesignFile from './UploadDesignFile';
import { Autocomplete, FormControl, MenuItem, Select, TextField } from '@mui/material';
import Add from '../../../assets/newicons/add.svg';
import frameImage from '../../../assets/newicons/Frame_image.png'
import noImage from '../../../assets/newimages/no_image.jpg'
import keyboardArrowDown from '../../../assets/newicons/keyboard_arrow_down.svg';
import Close from '../../../assets/newicons/close.svg';
import Dark from '../../../assets/newicons/dark.svg';
import onlyImage from '../../../assets/newicons/only_image.svg';
import refresh from '../../../assets/newicons/refresh.svg'
import deleteIcon from '../../../assets/newicons/delete.svg'
import { useTranslation } from 'react-i18next';
import "./Draft.scss";
import { newDelete, newGet, newPut } from '../../../API/axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { showAlert } from './AlertService';
import { useAuthContext } from '../../../context/useAuthContext';
import { set } from 'react-hook-form';


const Draft = ({ designDetails, setdesignDetails, projectDetails, assignee, internalApprover, clientApprover, removeDesignVersion }) => {
    const { t, i18n } = useTranslation();
    const [stageCount, setStageCount] = useState({});
    const [designVersion, setDesignVersion] = useState({})
    const navigate = useNavigate();

    const { userSettings } = useAuthContext();
    const onReplaceDesign = async (result) => {
        const stageId = Object.keys(result.stage)[0];
        try {
            const formData = {
                design_id: result?.design_id,
                project_Id: result?.project_id,
                stage_id: stageId,
                version_id: result?.version_id,
                is_latest: result?.is_latest
            }
            const data = await newPut(`design/update`, formData);
            if (data.status_code == 200) {
                // showAlert("Design Replaced successfully", "success");
                showAlert(i18n.t(data?.message[0]?.property_message), 'success');
                closeModal();
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    const groupByVersion = (data) => {
        return data.reduce((acc, item) => {
            const versionKey = `${item.version_number}_${item.is_customer}`;
            if (!acc[versionKey]) {
                acc[versionKey] = [];
            }
            acc[versionKey].push(item);
            return acc;
        }, {});
    }



    // const groupByVersion = (designs) => {

    //     return designs.reduce((acc, design) => {
    //         const version = design.version_number;
    //         if (!acc[version]) {
    //             acc[version] = [];
    //         }
    //         acc[version].push(design);
    //         return acc;
    //     }, {});
    // };
    // const onDeleteDesign = async (id) => {
    //     try {
    //         const data = await newDelete(`design/delete?design_id=${id}`);
    //         if (data.status_code == 200) {
    //             showAlert("Design deleted successfully", "success")
    //             closeModal();
    //         }
    //     }
    //     catch (error) {
    //         console.log(error)
    //     }
    // }



    const navigateToDesignVersion = (data) => {
        let value = {
            "project_id": data.project_id,
            "version_number": data.version_number,
            "projectDetails": projectDetails,
            "assignee": assignee,
            "internalApprover": internalApprover,
            "clientApprover": clientApprover,
            "design_id": data.design_id
        }
        navigate("/design-version", { state: value })
    }

    //  const onHandleDesignStages = (result) => {
    //      const stageCounts = {};
    //      result.forEach(item => {
    //              const stageValue = Object.values(item.stage)[0];  
    //              if (stageCounts[stageValue]) {
    //                  stageCounts[stageValue]++;
    //              } else {
    //                  stageCounts[stageValue] = 1;
    //              }
    //         });

    //         setStageCount(stageCounts)
    //  }

    const fetchData = () => {
        // designDetails.length > 0 && onHandleDesignStages(designDetails)
    }


    useEffect(() => {
        const designsByVersion = groupByVersion(designDetails);
        setDesignVersion(designsByVersion)

    }, [designDetails])


    const closeModal = () => {
        const modalElement = document.getElementById('exampleModal');
        const modalInstance = window.bootstrap.Modal.getInstance(modalElement);
        modalInstance.hide();
    };
    return (

        <div>
            {/* <div className='p-lg-4 p-md-3 p-3'>
                <div className='d-flex justify-content-between align-items-center gap-4 cursor_pointer' onClick={() => setIsOpenReview(!isOpenReview)}>
                    <div className='text_formate d-flex align-items-center gap-1'>
                        <span>
                            <img src={Dark} alt="Dark" />
                        </span>
                        <span>Draft</span>
                        <span>Draft</span>
                        <span>(0)</span>
                    </div>
                    <div className='d-xxl-flex d-xl-flex d-lg-flex d-md-flex d-sm-flex d-xs-block d-block text-center align-items-center gap-2'>
                        <div className='d-xxl-flex d-xl-flex d-lg-flex d-md-flex d-sm-flex d-xs-block d-block align-items-center gap-2 my-1'>
                            <button className='submit_approval my-1' onClick={handleDropdownClick}>
                                <span>
                                    Submit for approval
                                </span>
                            </button>
                            <button className='submit_not_approval my-1' onClick={handleDropdownClick}>
                                <span>
                                    Submit for approval
                                </span>
                            </button>
                        </div>
                        <div>
                            <img id="arrowIcon2" src={keyboardArrowDown} className={`keyboardArrow ${isOpenReview ? 'rotate-180' : ''}`} alt="logo" />
                        </div>
                    </div>
                </div>

           
                <div className={`transition ${isOpenReview ? 'visible' : ''}`}>
                    <div className='d-flex flex-wrap align-items-center gap-3'>

                        <div>
                            <div className='position-relative' >
                                <div className='add_reference_img d-flex justify-content-center align-items-center mt-4'>
                                    <img src={onlyImage} alt="onlyImage" />
                                </div>
                                <div className='whait_whit_text'>
                                    <div className='whait_red_text'>RETURNED</div>
                                </div>

                            </div>
                        </div>

                        <div className='position-relative' data-bs-toggle="modal" data-bs-target="#request_approval">
                            <div className='add_reference_img d-flex justify-content-center align-items-center mt-4'>
                                <img src={onlyImage} alt="onlyImage" />
                            </div>
                        </div>

                        <div className='cursor_pointer' data-bs-toggle="modal" data-bs-target="#add_design" type="button" >
                            <div className='add_reference d-flex justify-content-center align-items-center mt-4'>
                                <div className='text-center'>
                                    <img src={Add} className='Add' alt="logo" />
                                    <div className='add_reference_text mt-1'>
                                        Add design
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              
            </div> */}

            {/* 1 Modal  */}
            {/* <div className="modal fade" id="request_approval" tabIndex="-1" aria-labelledby="request_approvalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content px-3">
                        <div className="modal-header border-0 request_approval_modal">
                            Request approval
                        </div>
                        <div className="modal-body">
                            <div>
                                <div className="request_approval_modal mt-3 mb-2">State</div>
                                <FormControl sx={{ m: 0, minWidth: 120 }} size="small" style={{ width: "100%" }}>
                                    <div className='multiselect-user'>
                                        <Select
                                            
                                            name="channel"
                                           
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            className="w-100 from-input"
                                        >
                                            <MenuItem value="">
                                                <em>Select Channel</em>
                                            </MenuItem>
                                            <MenuItem value="Micoworks">Micoworks</MenuItem>
                                            <MenuItem value="Demo">Demo</MenuItem>
                                            <MenuItem value="Demo123">Demo123</MenuItem>
                                        </Select>
                                    </div>
                                </FormControl>
                            </div>
                            <div>
                                <div htmlFor="formGroupExampleInput" className="request_approval_modal mt-3 mb-2">
                                    Message
                                </div>
                                <input type="text" className="form-control rounded-5" id="formGroupExampleInput" placeholder="Example input placeholder" />
                            </div>
                        </div>
                        <div className="modal-footer gap-2 border-0">
                            <button type="button" className="cancel_modal_footer" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" className="send_modal_footer" data-bs-dismiss="modal">Create</button>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* 2 Modal  */}
            {/* <div className="modal fade" id="add_design" tabIndex="-1" aria-labelledby="add_designLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content px-3">
                        <div className="modal-header border-0">
                            <div className='upload_design'>
                                Upload design
                            </div>
                            <div className='cursor_pointer' data-bs-dismiss="modal">
                                <img src={Close} alt='Close' />
                            </div>
                        </div>
                        <div className="modal-body">
                            <UploadDesignFile />
                        </div>
                    </div>
                </div>
            </div> */}


            <div className='p-lg-4 p-md-3 p-3'>
                <div className='d-flex justify-content-between align-items-center gap-4 cursor_pointer'>
                    <div className='text_formate d-flex align-items-center gap-1'>
                        <span>{t("design")}</span>
                    </div>
                </div>

                {/* Show designs */}
                <div className='d-flex flex-wrap gap-2 gap-sm-3'>
                    {Object.keys(designVersion).map((version) => {

                        const [versionKey, isCustomer] = version.split('_');

                        return (
                            (userSettings?.role_id === 4) && isCustomer === '1' ? (
                                <div className='mx-1' key={version}>
                                    <div className='folder-stack'>
                                        {designVersion[version].map((degn, index) => (
                                            index >= 0 && index < 2 && (
                                                <div key={degn.image_id} onClick={() => navigateToDesignVersion(degn)} className='d-flex justify-content-center align-items-center cursor-pointer grid-item add_reference_img folder-item mt-4' style={{ zIndex: degn?.length - index, transform: `translateX(${index * 7}px)`, marginTop: `${index * 10}px` }}>
                                                    <img src={degn?.image_url} height={160} width={160} alt="onlyImage" />
                                                    <div className='overlay'>
                                                        <button style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={(e) => { e.stopPropagation(); removeDesignVersion(designVersion[version][0]?.design_id);}}>
                                                            <img style={{ width: '15px', height: '15px', marginRight: '5px' }} src={deleteIcon} alt="refresh" />
                                                            Remove
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                        ))}
                                    </div>
                                    {designVersion[version][0]?.stage === 'In Progress' &&
                                        <p style={{ color: "black", backgroundColor: "lightgray", paddingInline: "6px", borderRadius: "4px", position: "absolute", marginTop: "-130px", marginLeft: "20px", fontSize: "12px" }}>{designVersion[version][0]?.stage ? `${designVersion[version][0]?.stage} : ${designVersion[version]?.length}` : designVersion[version]?.length}</p>
                                    }

                                    {designVersion[version][0]?.stage === 'Approval' &&
                                        <p style={{ color: "black", backgroundColor: "yellow", paddingInline: "6px", borderRadius: "4px", position: "absolute", marginTop: "-130px", marginLeft: "20px", fontSize: "12px" }}>{designVersion[version][0]?.stage ? `${designVersion[version][0]?.stage} : ${designVersion[version]?.length}` : designVersion[version]?.length}</p>
                                    }
                                    {designVersion[version][0]?.stage === 'Approved' &&
                                        <p style={{ color: "black", backgroundColor: "limegreen", paddingInline: "6px", borderRadius: "4px", position: "absolute", marginTop: "-130px", marginLeft: "20px", fontSize: "12px" }}>{designVersion[version][0]?.stage ? `${designVersion[version][0]?.stage} : ${designVersion[version]?.length}` : designVersion[version]?.length}</p>
                                    }
                                    {designVersion[version][0]?.stage === 'Returned' &&
                                        <p style={{ color: "black", backgroundColor: "red", paddingInline: "6px", borderRadius: "4px", position: "absolute", marginTop: "-130px", marginLeft: "20px", fontSize: "12px" }}>{designVersion[version][0]?.stage ? `${designVersion[version][0]?.stage} : ${designVersion[version]?.length}` : designVersion[version]?.length}</p>
                                    }

                                    <p style={{ display: "flex", justifyContent: "center", backgroundColor: "black", color: "white", marginTop: "15px", borderRadius: "15px", fontSize: "13px", fontFamily: "Noto Sans", width: "70%", marginLeft: "auto", marginRight: "auto", paddingTop: "2px", paddingBottom: "2px" }}>
                                        {`Client Version ${versionKey}`}
                                    </p>
                                </div>
                            ) : (userSettings?.role_id === 1 || userSettings?.role_id === 2 || userSettings?.role_id === 3 || userSettings?.role_id === 5 || userSettings?.role_id === 6) &&
                            <div className='mx-1' key={version}>
                                <div className='folder-stack'>
                                    {designVersion[version].map((degn, index) => (
                                        index >= 0 && index < 2 && (
                                            <div key={degn.image_id} onClick={() => navigateToDesignVersion(degn)} className='d-flex justify-content-center align-items-center cursor-pointer grid-item add_reference_img folder-item mt-4' style={{ zIndex: degn?.length - index, transform: `translateX(${index * 7}px)`, marginTop: `${index * 10}px` }}>
                                                <img src={degn?.image_url} height={160} width={160} alt="onlyImage" />
                                                <div className='overlay'>
                                                    <button style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: "absolute" }} onClick={(e) => {  e.stopPropagation(); removeDesignVersion(designVersion[version][0]?.design_id)}}>
                                                        <img style={{ width: '15px', height: '15px', marginRight: '5px' }} src={deleteIcon} alt="refresh" />
                                                        Remove
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    ))}
                                </div>
                                {/* <div className='overlay'> */}
                                {/* <button style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: "absolute" }} onClick={() => removeDesignVersion(designVersion[version][0]?.design_id)}>
                                    <img style={{ width: '15px', height: '15px', marginRight: '5px' }} src={refresh} alt="refresh" />
                                    Remove
                                </button> */}
                                {/* </div> */}
                                {designVersion[version][0]?.stage === 'In Progress' &&
                                    <p style={{ color: "black", backgroundColor: "lightgray", paddingInline: "6px", borderRadius: "4px", position: "absolute", marginTop: "-130px", marginLeft: "20px", fontSize: "12px" }}>{designVersion[version][0]?.stage ? `${designVersion[version][0]?.stage} : ${designVersion[version]?.length}` : designVersion[version]?.length}</p>
                                }

                                {designVersion[version][0]?.stage === 'Approval' &&
                                    <p style={{ color: "black", backgroundColor: "yellow", paddingInline: "6px", borderRadius: "4px", position: "absolute", marginTop: "-130px", marginLeft: "20px", fontSize: "12px" }}>{designVersion[version][0]?.stage ? `${designVersion[version][0]?.stage} : ${designVersion[version]?.length}` : designVersion[version]?.length}</p>
                                }
                                {designVersion[version][0]?.stage === 'Approved' &&
                                    <p style={{ color: "black", backgroundColor: "limegreen", paddingInline: "6px", borderRadius: "4px", position: "absolute", marginTop: "-130px", marginLeft: "20px", fontSize: "12px" }}>{designVersion[version][0]?.stage ? `${designVersion[version][0]?.stage} : ${designVersion[version]?.length}` : designVersion[version]?.length}</p>
                                }
                                {designVersion[version][0]?.stage === 'Returned' &&
                                    <p style={{ color: "black", backgroundColor: "red", paddingInline: "6px", borderRadius: "4px", position: "absolute", marginTop: "-130px", marginLeft: "20px", fontSize: "12px" }}>{designVersion[version][0]?.stage ? `${designVersion[version][0]?.stage} : ${designVersion[version]?.length}` : designVersion[version]?.length}</p>
                                }
                                <p style={{ display: "flex", justifyContent: "center", backgroundColor: "black", color: "white", marginTop: "15px", borderRadius: "15px", fontSize: "13px", fontFamily: "Noto Sans", width: "70%", marginLeft: "auto", marginRight: "auto", paddingTop: "2px", paddingBottom: "2px" }}>
                                    {isCustomer === '1' ? `Client Version ${versionKey}` : `Version ${versionKey}`}
                                    {/* {designVersion[version][0]?.is_customer === 1 ? `Client Version ${version}` : `Version ${version}`} */}
                                </p>
                            </div>
                        )
                    })}


                    {(userSettings?.role_id === 1 || userSettings?.role_id === 2 || userSettings?.role_id === 5) &&
                        <div className='cursor_pointer' style={{ marginTop: '12px' }} data-bs-toggle="modal" data-bs-target="#add_design" type="button" >
                            <div className='add_reference d-flex justify-content-center align-items-center'>
                                <div className='text-center'>
                                    <img src={Add} className='Add' alt="logo" />
                                    <div className='add_reference_text mt-1'>
                                        {t("addDesign")}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                </div>

            </div>

            {/* 1 Modal  */}
            <div className="modal fade" id="request_approval" tabIndex="-1" aria-labelledby="request_approvalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content px-3">
                        <div className="modal-header border-0 request_approval_modal">
                            Request approval
                        </div>
                        <div className="modal-body">
                            <div>
                                <div className="request_approval_modal mt-3 mb-2">State</div>
                                <FormControl sx={{ m: 0, minWidth: 120 }} size="small" style={{ width: "100%" }}>
                                    <div className='multiselect-user'>
                                        <Select

                                            name="channel"

                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            className="w-100 from-input"
                                        >
                                            <MenuItem value="">
                                                <em>Select Channel</em>
                                            </MenuItem>
                                            <MenuItem value="Micoworks">Micoworks</MenuItem>
                                            <MenuItem value="Demo">Demo</MenuItem>
                                            <MenuItem value="Demo123">Demo123</MenuItem>
                                        </Select>
                                    </div>
                                </FormControl>
                            </div>
                            <div>
                                <div htmlFor="formGroupExampleInput" className="request_approval_modal mt-3 mb-2">
                                    Message
                                </div>
                                <input type="text" className="form-control rounded-5" id="formGroupExampleInput" placeholder="Example input placeholder" />
                            </div>
                        </div>
                        <div className="modal-footer gap-2 border-0">
                            <button type="button" className="cancel_modal_footer" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" className="send_modal_footer" data-bs-dismiss="modal">Create</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* 2 Modal  */}
            <div className="modal fade" id="add_design" tabIndex="-1" aria-labelledby="add_designLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content px-3">
                        <div className="modal-header border-0">
                            <div className='upload_design'>
                                Upload design
                            </div>
                            <div className='cursor_pointer' data-bs-dismiss="modal" >
                                <img src={Close} alt='Close' />
                            </div>
                        </div>
                        <div className="modal-body">
                            <UploadDesignFile screen="modal" setdesignDetails={setdesignDetails} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Draft;
