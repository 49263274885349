import React, { useEffect, useRef, useState } from 'react';
import commentIcon from '../../../assets/newicons/chat_bubble.svg';
import publicIcon from '../../../assets/newicons/public-Icon.svg';
import detailIcon from '../../../assets/newicons/detailIcon.svg';
import chevronLeft from '../../../assets/icons/chevron-left-solid.svg';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormHelperText, InputLabel, Menu, MenuItem, Select, Tooltip, Zoom } from '@mui/material';
import trueIcon from '../../../assets/newicons/tick-black-icon.svg';
import { Mention, MentionsInput } from 'react-mentions';
import { useTranslation } from 'react-i18next';
import defaultStyle from "./defaultStyle";
import "./commentAndTask.scss"
import { showAlert } from './AlertService';
import { newDelete, newGet, newPost, newPut, post } from '../../../API/axios';
import ellipsis_vertical from '../../../assets/newicons/ellipsis_vertical.svg'
import defaultStyleForReply from './defaultStyleForReply';
import { useAuthContext } from '../../../context/useAuthContext';
import { useLocation } from 'react-router-dom';
import { useSocketContext } from '../../../context/useSocketContext';
import { parseMentions } from '../../../helpers/parseMentions';
import { Brightness1 } from '@mui/icons-material';
import { useReferenceContext } from '../../../context/useReferenceContext';

const CommentAndTask = ({ activeImag, designDetails, channelId, createdProjectId, ProjectId, getReferences, referenceList, referenceComments, setReferenceComments, selectedImage, designVersion, design_id }) => {
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const [tab, setTab] = useState(1)
    const [hoveredCommentId, setHoveredCommentId] = useState(null);
    const [referenceData, setReferenceData] = useState('');
    const [commentStatus, setCommentStatus] = useState('1');
    const [commentType, setCommentType] = useState('1');
    const [designId, setDesignId] = useState(0);
    const [replyValue, setReplyValue] = useState('');
    const [replyActions, setReplyActions] = useState();
    const { userSettings } = useAuthContext();
    const [openAlert, setOpenAlert] = useState(false);
    const [mentionsUsers, setMentionsUsers] = useState([]);
    const [projectUsers, setProjectUsers] = useState([]);
    const [watchListUser, setWatchListUser] = useState([]);
    const [newProjectUsers, setNewProjectUsers] = useState([]);

    const { onMessage } = useSocketContext();

    const [comments, setComments] = useState([]);
    const [commentWithoutAnnotate, setCommentWithoutAnnotate] = useState('');
    const [editComment, setEditComment] = useState('');
    const [editCommentData, setEditCommentData] = useState();
    const [replyData, setReplyData] = useState('');
    const [editReplyData, setEditReplyData] = useState('');
    const [newComment, setNewComment] = useState(false);
    const [editCommentFlag, setEditCommentFlag] = useState(false);
    const [newReply, setNewReply] = useState(false);
    const [editReply, setEditReply] = useState(false);
    const mentionInputRef = useRef(null);
    const AddCommentRef = useRef(null);
    const addReplyRef = useRef(null);
    const commentsEndRef = useRef(null);
    const commentsTopRef = useRef(null);

    const [userList, setUserList] = useState([]);
    const [isReplyFieldOpen, setIsReplyFieldOpen] = useState(false);
    const [replyCommentId, setReplyCommentId] = useState();
    const [commentIdforReply, setCommentIdforReply] = useState();
    const [allUsers, setAllUsers] = useState([]);
    const { wireframeId, setWireframeId, referencesList } = useReferenceContext()
    const [replyDeleteAlert, setReplyDeleteAlert] = useState(null);
    const [commentDeleteAlert, setCommentDeleteAlert] = useState(null);

    // useEffect(() => {
    //     if (localStorage.getItem('projectUsers')) {
    //         const projectUsers = JSON.parse(localStorage.getItem('projectUsers'));
    //         setProjectUsers(projectUsers)
    //     }
    //     if (localStorage?.getItem('watchListUser') !== ("undefined" || null)) {
    //         const watchListUser = JSON.parse(localStorage?.getItem('watchListUser'));
    //         setWatchListUser(watchListUser)
    //     }
    // }, [])

    const scrollToBottom = () => {
        commentsEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    const scrollToTop = () => {
        commentsTopRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    const siteId = localStorage?.getItem('siteId');

    useEffect(() => {
        if (onMessage) {
            try {
                const socketData = onMessage;
                console.log("Received message", socketData);
                if (socketData.message == "comment_added") {
                    if (comments?.length === 0 || comments === (undefined || null)) {
                        setComments([socketData.data[0]]);
                    } else {
                        let datacomments = JSON.parse(JSON.stringify(comments));
                        datacomments = [...datacomments, socketData.data[0]];
                        setComments(datacomments);
                    }
                } else if (socketData.message == "comment_updated") {
                    if (socketData.data[0]?.comment_status === 1) {
                        let datacomments = JSON.parse(JSON.stringify(comments));
                        const editedDataIndex = datacomments.findIndex((d) => d.comment_id === socketData.data[0]?.comment_id);
                        if (editedDataIndex !== -1) {
                            datacomments[editedDataIndex].comment_status = socketData.data[0].comment_status;
                        }
                        setComments(datacomments);
                    } else if (socketData.data[0]?.is_public === 1) {
                        let datacomments = JSON.parse(JSON.stringify(comments));
                        const editedDataIndex = datacomments.findIndex((d) => d.comment_id === socketData.data[0]?.comment_id);
                        if (editedDataIndex !== -1) {
                            datacomments[editedDataIndex].is_public = socketData.data[0].is_public;
                            datacomments[editedDataIndex].comment_content = socketData.data[0].comment_content;
                        }
                        setComments(datacomments);
                    }
                    else {
                        let datacomments = JSON.parse(JSON.stringify(comments));
                        const editedDataIndex = datacomments.findIndex((d) => d.comment_id === socketData.data[0]?.comment_id);
                        if (editedDataIndex !== -1) {
                            datacomments[editedDataIndex].comment_content = socketData.data[0].comment_content;
                        }
                        setComments(datacomments);
                    }
                } else if (socketData.message == "comment_deleted") {
                    let datacomments = JSON.parse(JSON.stringify(comments));
                    const editedData = datacomments.filter((d) => d.comment_id !== socketData.data[0]?.comment_id)
                    setComments(editedData);
                }
                else if (socketData.message == "reply_added") {
                    let datacomments = JSON.parse(JSON.stringify(comments));
                    const editedData = datacomments.find((d) => d.comment_id == socketData.data[0]?.comment_id);
                    if (editedData) {
                        if (!editedData.replies) {
                            editedData.replies = [];
                        }
                        editedData.replies.push(socketData.data[0]);
                    }
                    setComments(datacomments);
                }
                else if (socketData.message == "reply_updated") {
                    // let commentData = JSON.parse(JSON.stringify(comments));
                    // const editData = commentData.find((c) => c.comment_id === replyCommentId);
                    // let replyData = editData?.replies?.find((r) => r.reply_id === socketData.data[0]?.reply_id);
                    // replyData.reply_content = socketData.data[0]?.reply_content;
                    // setComments(commentData);
                    let commentData = JSON.parse(JSON.stringify(comments));

                    const editData = commentData.find((c) => c.comment_id === commentIdforReply);
                    if (editData) {
                        let replyData = editData.replies?.find((r) => r.reply_id === socketData.data[0]?.reply_id);
                        if (replyData) {
                            replyData.reply_content = socketData.data[0]?.reply_content;
                        }
                    }
                    setComments(commentData);
                } else if (socketData.message == "reply_deleted") {
                    let commentData = JSON.parse(JSON.stringify(comments));
                    const editData = commentData.find((c) => c.comment_id === socketData.data[0]?.comment_id);
                    if (editData && editData.replies) {
                        editData.replies = editData.replies.filter((r) => r.reply_id !== socketData.data[0]?.reply_id);
                    }
                    setComments(commentData);
                } else if (socketData.message == "comment_status_updated") {
                    let datacomments = JSON.parse(JSON.stringify(comments));
                    const editedDataIndex = datacomments.findIndex((d) => d.comment_id === socketData.data[0]?.comment_id);
                    if (editedDataIndex !== -1) {
                        datacomments[editedDataIndex].comment_status = socketData.data[0].comment_status;
                    }
                    setComments(datacomments);
                }
            } catch (error) {
                console.error("Failed to parse WebSocket message", error);
            }
        }
    }, [onMessage]);

    const handleCommentStatus = (e) => {
        setCommentStatus(e.target.value)
    }
    const handleDesignId = (e) => {
        setDesignId(e.target.value)
        // setWireframeId(0)
    }

    const handleCommentType = (e) => {
        setCommentType(e.target.value)
        let type = e.target.value
        if (type === "1") {
            setWireframeId(referencesList.wireframe_id);
            setDesignId(0)
        } else if (type === "2") {
            setWireframeId(0)
            // setDesignId(0)
        } else {
            setWireframeId(0)
            setDesignId(0)
        }
    }

    // const isValidURL = (text) => {
    //     const urlPattern = new RegExp(
    //         '((https?:\\/\\/)?' + // Protocol (http or https)
    //         '((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.)+[a-zA-Z]{2,}|' + // Domain name
    //         '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
    //         '(\\:\\d+)?(\\/[-a-zA-Z\\d%_.~+]*)*' + // Port and path
    //         '(\\?[;&a-zA-Z\\d%_.~+=-]*)?' + // Query string
    //         '(\\#[-a-zA-Z\\d_]*)?)', 'i' // Fragment locator
    //     );
    //     return urlPattern.test(text);
    // };

    const parseTextWithUrlsAndMentions = (text, userList) => {
        // Ensure text is a string
        if (typeof text !== 'string') {
            text = String(text);
        }
    
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        const mentionRegex = /@\[(.*?)\]\((.*?)\)|@(\w+)/g;
    
        const parts = [];
        let lastIndex = 0;
        let match;
    
        while ((match = mentionRegex.exec(text)) !== null) {
            const [fullMatch, displayName, userId, simpleMention] = match;
            const start = match.index;
    
            // Add text before the mention
            if (start > lastIndex) {
                const textBeforeMention = text.substring(lastIndex, start);
                const textParts = textBeforeMention.split(urlRegex);
                textParts.forEach((part, index) => {
                    if (urlRegex.test(part)) {
                        parts.push(<a key={`url-${index}`} href={part} target="_blank" rel="noopener noreferrer">{part}</a>);
                    } else {
                        parts.push(part);
                    }
                });
            }
    
            // Add the mention
            if (userId) {
                const user = userList.find(user => user.id === userId);
                parts.push(
                    <span style={{ color: '#3f51b5', cursor: 'pointer' }} key={`mention-${userId}`} className="mention">
                        @{user ? user.display : displayName}
                    </span>
                );
            } else if (simpleMention) {
                parts.push(
                    <span key={`mention-${simpleMention}`} className="mention">
                        @{simpleMention}
                    </span>
                );
            }
    
            lastIndex = start + fullMatch.length;
        }
    
        // Add remaining text after the last mention
        if (lastIndex < text.length) {
            const textAfterLastMention = text.substring(lastIndex);
            const textParts = textAfterLastMention.split(urlRegex);
            textParts.forEach((part, index) => {
                if (urlRegex.test(part)) {
                    parts.push(<a key={`url-${lastIndex + index}`} href={part} target="_blank" rel="noopener noreferrer">{part}</a>);
                } else {
                    parts.push(part);
                }
            });
        }
    
        return parts;
    };

    

    const fetchUsers = async () => {
        if (userList.length === 0) {
            try {
                const data = await newGet(`project/usersByRole?role_name=channel`);
                const dataClient = await newGet(`project/usersByRole?role_name=client`);
                const allUsers = [...data?.result, ...dataClient?.result];
                setAllUsers(allUsers);

                const newList = allUsers?.map(user => ({
                    id: user?.account_login_id,
                    display: user?.username,
                    email: user.email
                }));
                setUserList(newList || []);

            } catch (error) {
                console.log("error:???????", error);
            }
        }
    }

    const fetchComments = async () => {
        try {
            if (createdProjectId || ProjectId) {
                let data = await newGet(`comment/fetchComments?project_id=${createdProjectId || ProjectId}`);
                data = data?.result?.map((r) => ({
                    ...r,
                    replies: r.replies.reverse(),
                }));

                setComments(data);
            }

        } catch (error) {
            console.log("error:???????", error);
        }
    }


    const onChange = (e, newValue, newPlainTextValue, mentions) => {
        setCommentWithoutAnnotate(e.target.value);
        setMentionsUsers(mentions)
    };

    const onChangeReply = (e, newValue, newPlainTextValue, mentions) => {
        setReplyValue(e.target.value);
        setMentionsUsers(mentions);
    }
    const onReplyEditValue = (e, newValue, newPlainTextValue, mentions) => {
        setReplyValue(e.target.value);
        setMentionsUsers(mentions);
    }

    const onEditCommentChange = (e, newValue, newPlainTextValue, mentions) => {
        setEditComment(e.target.value);
        setMentionsUsers(mentions);
    }

    // const onReplyChange = (e, comment) => {
    //     let a = JSON.parse(JSON.stringify(referenceComments));

    //     a[0].comments = a[0].comments.map((c) => {
    //         if (JSON.stringify(c) == JSON.stringify(comment)) {
    //             c.replyText = e.target.value;
    //         } return c;
    //     });
    //     setReferenceComments(a);
    // }

    // const onAdd = (id) => {
    //     setMentionUserId((prevIds) => (prevIds ? `${prevIds}, ${id}` : id));
    // };

    const handleAddComment = async (id, comment, action, replyItem) => {
        let allIdsPresent = mentionsUsers?.every(mention => projectUsers.some(item => item.id === mention.id));
        let newUsers = mentionsUsers.filter(newItem => !projectUsers.some(user => user.id === newItem.id));
        const newUsersList = newUsers.map(user => user?.display).join(',')
        setNewProjectUsers(newUsersList)
        if (action === "new_comment") {
            if (mentionsUsers.length === 0 || allIdsPresent) {
                addComment()
            } else {
                setNewComment(true)
                setOpenAlert(true)
            }
        } else if (action === "edit_comment") {
            if (mentionsUsers.length === 0 || allIdsPresent) {
                onSubmitEditComment(id, comment)
            } else {
                setEditCommentFlag(true)
                setOpenAlert(true)
            }
        } else if (action === "reply_comment") {
            if (mentionsUsers.length === 0 || allIdsPresent) {
                onAddReply(id, comment)
            } else {
                setNewReply(true)
                setReplyData(comment)
                setOpenAlert(true)
            }
        } else if (action === "edit_reply") {
            if (mentionsUsers.length === 0 || allIdsPresent) {
                onSubmitEditReply(replyItem)
            } else {
                setEditReply(true)
                setOpenAlert(true)
            }
        }
    }

    const formatMentionUsers = (text) => {
        let ids = [...text.matchAll(/\(([\da-fA-F-]{36})\)/g)].map(match => match[1]);
        let result = ids.length === 1 ? ids[0] : new Set(ids);
        return result
    }

    const addComment = async () => {
        const formattedContent = mentionsUsers.reduce((acc, user) => {
            const mentionTag = `@[${user.display}](${user.id})`;
            return acc.replace(`@${user.display}`, mentionTag);
        }, commentWithoutAnnotate);
        const cleanContent = formattedContent.replace(/\n/g, '');

        if (!cleanContent.trim()) {
            return;
        }
        const data = {
            project_id: createdProjectId || ProjectId,
            content: cleanContent,
            mention_user_id: mentionsUsers.map(user => user?.id).join(','),
            comment_status: false,
            is_public: (userSettings?.role_id == 4 || userSettings?.role_id == 6) ? true : false,
            wireframe_id: wireframeId,
            design_id: design_id,
            version_number: designVersion,

        }

        try {
            await newPost(`comment/createComment`, data).then((response) => {
                if (response && (response.status_code == 200 || response.status_code == 201)) {
                    setCommentWithoutAnnotate('');
                    setMentionsUsers([]);
                    setNewComment(false);
                    setTimeout(() => {
                        scrollToTop();
                    }, 300)
                }
            });


        } catch (e) {
            console.log('error: ', e)
        }
    }

    const updateWatchlist = async () => {
        let newUsers = mentionsUsers.filter(newItem => !projectUsers.some(user => user.id === newItem.id));
        let watchlistUsers = [...(watchListUser ?? []), ...(newUsers ?? [])]

        const formData = {
            project_id: ProjectId,
            site_id: siteId,
            watchlist: watchlistUsers?.map(user => user?.id).join(',')
        }
        try {
            const data = await newPut(`project`, formData);
            if (data.status_code == 200) {
                // showAlert("watchlist updated successfully", "success")
                showAlert(i18n.t(data?.message[0]?.property_message), 'success');
                if (newComment) {
                    addComment()
                } else if (editCommentFlag) {
                    onSubmitEditComment(editCommentData.comment_id, editCommentData)
                } else if (newReply) {
                    onAddReply(replyData.comment_id, replyData)
                } else if (editReply) {
                    onSubmitEditReply(editReplyData)
                }
                getProjectDetail()
            }
        } catch (error) {
            console.log("error:", error);
        }
    }

    const getProjectDetail = async () => {
        if (ProjectId && siteId) {
            try {
                const data = await newGet(
                    `project/getProjectDetails?project_id=${ProjectId}&site_id=${siteId}`
                );
                const assigneeList = makeUserArray(data?.result[0]?.assign_to_user_name)
                const internalApproverList = makeUserArray(data?.result[0]?.internal_approver_username)
                const clientApproverList = makeUserArray(data?.result[0]?.client_approver_username)
                const watchListUsers = makeUserArray(data?.result[0]?.watchlist)
                setWatchListUser(watchListUsers)
                const projectUsers = [...(assigneeList ?? []), ...(internalApproverList ?? []), ...(clientApproverList ?? []), ...(watchListUsers ?? [])]
                setProjectUsers(projectUsers)
            } catch (error) {
                console.log("error:???????", error);
            }
        }
    };

    function makeUserArray(input) {
        if (input) {
            const objectsArray = input.split("},").map(item => {
                item = item.trim().endsWith("}") ? item : item + "}";

                const idMatch = item.match(/id:([a-f0-9\-]+)/);
                const usernameMatch = item.match(/username:([^,}]+)/);

                return {
                    id: idMatch ? idMatch[1] : null,
                    username: usernameMatch ? usernameMatch[1].trim() : null
                };
            });
            return objectsArray
        }
        else {
            return
        }
    }

    const onHandleReplyInputField = (id) => {
        setReplyCommentId(id)
        setIsReplyFieldOpen(true);
        setMentionsUsers([])
        setEditCommentData()
        setReplyActions(null);
    }

    const extractMentionsFromComment = (comment) => {
        const mentionRegex = /\@\[(.*?)\]\((.*?)\)/g;
        let matches;
        const mentionedUsers = [];

        while ((matches = mentionRegex.exec(comment)) !== null) {
            mentionedUsers.push({ id: matches[2], display: matches[1] });
        }

        return mentionedUsers;
    };

    const onUpadateComment = async (id, comment) => {
        setCommentWithoutAnnotate('')
        setReplyCommentId()
        setIsReplyFieldOpen(false);
        const mentionedUsers = extractMentionsFromComment(comment.comment_content);
        setMentionsUsers(mentionedUsers);
        setReplyActions(id)
        setEditComment(comment?.comment_content)
        setEditCommentData(comment)
        setAnchorEl(null)

        setTimeout(() => {
            if (mentionInputRef.current) {
                mentionInputRef.current.focus();
            }
        }, 0);
    }

    const onSubmitEditComment = async (id, comment) => {
        const formattedContent = mentionsUsers.reduce((acc, user) => {
            const mentionTag = `@[${user.display}](${user.id})`;
            return acc.replace(`@${user.display}`, mentionTag);
        }, editComment);
        const cleanContent = formattedContent.replace(/\n/g, '');

        if (!cleanContent.trim()) {
            return;
        }
        const data = {
            project_id: ProjectId,
            comment_id: id,
            content: cleanContent,
            comment_status: false,
            mention_user_id: mentionsUsers.map(user => user?.id).join(','),
        }
        try {
            await newPut(`comment/updateComment`, data).then((response) => {
                if (response?.status_code == 200) {
                    // const commentData = JSON.parse(JSON.stringify(comments));
                    // const editData = commentData.find((c) => c.comment_id === comment.comment_id);
                    // editData.content = formattedContent;
                    // setComments(commentData);
                    setReplyActions(null);
                    setMentionsUsers([]);
                    setEditCommentFlag(false);
                }
            });
        }
        catch (error) {
            console.log("error:", error);
        }

    }

    const onDeleteComment = async (id, comment) => {
        setCommentDeleteAlert(id)
        setReplyDeleteAlert(null);
        setAnchorEl(null);
        setMenuOpenCommentId(null);
        // try {
        //     await newDelete(`comment/deleteComment?comment_id=${id}`);
        //     handleClose()
        // }
        // catch (error) {
        //     console.log(error)
        // }
    }

    const handleDeleteComment = async (id) => {
        try {
            await newDelete(`comment/deleteComment?comment_id=${id}`);
            handleClose();
            setCommentDeleteAlert(null);
        }
        catch (error) {
            console.log(error)
        }

    }

    const onResolveComment = async (id, comment) => {
        try {
            if (id) {
                await newPut(`comment/updateCommentStatus`,
                    {
                        "comments": [
                            {
                                comment_id: id,
                                comment_status: true
                            }
                        ]
                    }
                )
                try {
                    const data = {
                        comment_id: id,
                        reply_content: "Marked as resolved",
                        task_flag: false,
                        mention_user_id: ""
                    }
                    await newPost(`reply/create`, data).then((response) => {
                        console.log('response: ', response)
                    })
                }
                catch (error) {
                    console.log(error)
                }
                // fetchComments()
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    const onMakeCommentPublic = async (id, comment) => {
        try {
            if (id) {
                await newPut(`comment/updateComment`, {
                    project_id: ProjectId,
                    comment_id: id,
                    content: comment.comment_content,
                    is_public: true
                })
                handleClose()
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    // const fetchReply = async (data) => {
    //     try {
    //         if (data.length > 0) {
    //             let replyDataArray = []

    //             for (const element of data) {
    //                 try {
    //                     const response = await newGet(`reply/getReply?comment_id=${element}`);
    //                     if (response?.status_code === '200') {
    //                         let replyResponse = {
    //                             "comment_id": element,
    //                             "replyData": response.result
    //                         }

    //                         replyDataArray.push(replyResponse)
    //                     }
    //                     setReplyData(replyDataArray)
    //                 } catch (error) {
    //                     console.error("Error fetching reply data:", error);
    //                 }
    //             }
    //         }
    //     }
    //     catch (error) {
    //         console.log(error)
    //     }
    // }

    const onAddReply = async (id, comment, reOpen) => {
        handleClose()
        const formattedContent = mentionsUsers.reduce((acc, user) => {
            const mentionTag = `@[${user.display}](${user.id})`;
            return acc.replace(`@${user.display}`, mentionTag);
        }, replyValue);
        const cleanContent = formattedContent.replace(/\n/g, '');

        if (!cleanContent.trim() && !reOpen) {
            return;
        }
        const data = {
            comment_id: id,
            reply_content: reOpen ? reOpen : cleanContent,
            task_flag: false,
            mention_user_id: mentionsUsers?.map(user => user?.id).join(','),
            // ...(comment.comment_status && { comment_status: false })
        }

        try {
            await newPost(`reply/create`, data).then((response) => {
                if (response && (response.status_code == 200 || response.status_code == 201)) {
                    setReplyValue("");
                    setIsReplyFieldOpen(false)
                    setNewReply(false);
                }
            })
            if (comment.comment_status) {
                await newPut(`comment/updateCommentStatus`,
                    {
                        "comments": [
                            {
                                comment_id: id,
                                comment_status: false
                            }
                        ]
                    }
                )
            }
        } catch (error) {
            console.log("error:", error);
        }
    }

    const onUpdateReply = async (reply, commentId) => {
        setReplyCommentId(reply.reply_id);
        setCommentIdforReply(commentId)
        setCommentWithoutAnnotate('')
        const mentionedUsers = extractMentionsFromComment(reply.reply_content);
        setMentionsUsers(mentionedUsers);
        setReplyValue(reply.reply_content);
        setEditReplyData(reply)
        setAnchorElReply(null);
        setIsReplyFieldOpen(true)
    }

    const onSubmitEditReply = async (result) => {
        const formattedContent = mentionsUsers.reduce((acc, user) => {
            const mentionTag = `@[${user.display}](${user.id})`;
            return acc.replace(`@${user.display}`, mentionTag);
        }, replyValue);
        const cleanContent = formattedContent.replace(/\n/g, '');

        if (!cleanContent.trim()) {
            return;
        }
        const data = {
            reply_id: result?.reply_id,
            reply_content: cleanContent,
            task_flag: false,
            mention_user_id: mentionsUsers.map(user => user?.id).join(','),
        }
        try {
            await newPut(`reply/update`, data).then((response) => {
                if (response.status_code == 200) {
                    // const commentData = JSON.parse(JSON.stringify(comments));
                    // const editData = commentData.find((c) => c.comment_id === comment.comment_id);
                    // let replyData = editData?.replies?.find((r) => r.reply_id === result.reply_id);
                    // replyData.reply_content = formattedContent;
                    // setComments(commentData);
                    setEditReply(false)
                    setIsReplyFieldOpen(false)
                }
            })
        }
        catch (error) {
            console.log("error:", error);
        }
    }

    const onDeleteReply = async (id) => {
        setReplyDeleteAlert(id)
        setCommentDeleteAlert(null);
        setAnchorElReply(null)
        setMenuOpenReplyId(null)
        // try {
        //     await newDelete(`reply/delete?reply_id=${id}`).then((response) => {
        //         onCloseReplyMenu();
        //     })
        // }
        // catch (error) {
        //     console.log(error)
        // }
    }

    const handleReplyDelete = async (id) => {
        try {
            await newDelete(`reply/delete?reply_id=${id}`).then((response) => {
                onCloseReplyMenu();
                setReplyDeleteAlert(null);
            })
        }
        catch (error) {
            console.log(error)
        }
    }


    const filterData = (data) => {
        let dd = JSON.parse(JSON.stringify(data));
        if (wireframeId) {
            dd = data.filter((d) => wireframeId == d?.wireframe_id);
        } else if (design_id || designId) {
            dd = data.filter((d) => d?.design_id == (design_id || designId));
        } else if (commentType === "2") {
            dd = data.filter((d) => null == d?.wireframe_id);
        }
        switch (commentStatus) {
            case '1':
                return dd.filter((d) => d.comment_status == false);
                break;
            case '2':
                return dd.filter((d) => d.comment_status == true);
                break;
            case '3':
                return dd;
                break;
            default:
                break;
        }
    }

    const [menuOpenCommentId, setMenuOpenCommentId] = useState(null);
    const [menuOpenReplyId, setMenuOpenReplyId] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElReply, setAnchorElReply] = useState(null);
    const handleClick = (event, comment_id) => {
        setAnchorEl(event.currentTarget);
        setMenuOpenCommentId(comment_id);
    };

    const onHandleReplyMenu = (event, reply_id) => {
        setAnchorElReply(event.currentTarget);
        setMenuOpenReplyId(reply_id);
    }

    const handleClose = () => {
        setAnchorEl(null);
        setMenuOpenCommentId(null);
    };

    const onCloseReplyMenu = () => {
        setAnchorElReply(null)
        setMenuOpenReplyId(null)
    }

    useEffect(() => {
        fetchUsers()
        getProjectDetail()
        fetchFileterData();
    }, [selectedImage]);


    const fetchFileterData = () => {
        if (selectedImage) {
            const refernceData = referenceList.filter(reference => reference.reference_image_url == selectedImage)
            console.log('refernceData: ', refernceData)
            setReferenceData(refernceData)
        }
    }

    const handleRejection = () => {
        setOpenAlert(false);
    };

    const handleCloseDialog = () => {
        // setOpenAlert(false);
        // setMentionUserId()
    };

    const handleConfirmation = () => {
        setOpenAlert(false);
        updateWatchlist()
    };

    const getCommentedUser = (id) => {
        const user = allUsers?.find((user) => user.account_login_id === id);
        return user ? user.username : ""
    }

    function formatCommentDate(dateString) {
        const date = new Date(dateString);
        const now = new Date();
        const pad = (num) => num.toString().padStart(2, '0');
        const time = `${pad(date.getHours())}:${pad(date.getMinutes())}`;

        const isToday = date.toDateString() === now.toDateString();

        const yesterday = new Date(now);
        yesterday.setDate(now.getDate() - 1);
        const isYesterday = date.toDateString() === yesterday.toDateString();

        if (isToday) {
            return `${time} today`;
        } else if (isYesterday) {
            return `${time} yesterday`;
        } else {
            const day = pad(date.getDate());
            const month = date.toLocaleString('default', { month: 'long' });
            const year = date.getFullYear();

            return `${time} ${day} ${month} ${year}`;
        }
    }

    const formatCommentDate2 = (dateString) => {
        const date = new Date(dateString);
        const formattedDate = date.toLocaleDateString('en-GB', { day: 'numeric', month: 'short' });
        const formattedTime = date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
        return `${formattedTime} ${formattedDate}`;
    };

    useEffect(() => {
        fetchComments();
    }, [])

    // const getReplyData = (data) => return <p></p>
    const handleMouseEnter = (id) => {
        setHoveredCommentId(id);
    };

    const handleMouseLeave = () => {
        setHoveredCommentId(null);
    };
    const handleKeyDown = (e, id, comment, type, replyItem) => {
        if (e.key === 'Enter') {
            if (e.shiftKey) {
                // Shift + Enter: Insert a new line
                e.preventDefault();

                const cursorPosition = e.target.selectionStart;
                let updatedText;

                if (type === "new_comment") {
                    updatedText = insertAtCursor(commentWithoutAnnotate, '\n', cursorPosition);
                    setCommentWithoutAnnotate(updatedText);
                } else if (type === "edit_comment") {
                    updatedText = insertAtCursor(editComment, '\n', cursorPosition);
                    setEditComment(updatedText);
                } else if (type === "reply_comment" || type === "edit_reply") {
                    updatedText = insertAtCursor(replyValue, '\n', cursorPosition);
                    setReplyValue(updatedText);
                }

                // Maintain the cursor position after the state update
                setTimeout(() => {
                    e.target.focus();
                    e.target.setSelectionRange(cursorPosition + 1, cursorPosition + 1);
                }, 0);
            } else {
                // Enter: Submit the comment
                e.preventDefault();

                if (type === "new_comment") {
                    handleAddComment("", "", type);
                } else if (type === "edit_comment") {
                    handleAddComment(id, comment, type);
                } else if (type === "reply_comment") {
                    handleAddComment(id, comment, type);
                } else if (type === "edit_reply") {
                    handleAddComment(id, comment, type, replyItem);
                }
            }
        }
    };

    const insertAtCursor = (text, insertText, position) => {
        // Check if the cursor is at the end of the text
        const isCursorAtEnd = position === text.length;

        // If the cursor is at the end, append the insertText
        if (isCursorAtEnd) {
            return text + insertText;
        }

        // Adjust the cursor position if it falls within any mentions
        let adjustedPosition = position;
        let currentIndex = text.indexOf('@[', 0);

        // Iterate through all occurrences of mentions
        while (currentIndex !== -1) {
            // If the cursor is within the mention range, adjust it to after the mention
            if (adjustedPosition > currentIndex && adjustedPosition <= currentIndex + 300) {
                adjustedPosition += currentIndex + 300;
            }
            // Find the next occurrence of a mention
            currentIndex = text.indexOf('@[', currentIndex + 1);
        }

        // Insert the text at the adjusted position
        return text.slice(0, adjustedPosition) + insertText + text.slice(adjustedPosition);
    };


    const onClickOfReadMore = (comment) => {
        // comment.isSlice = !comment.isSlice;
        let datacomments = JSON.parse(JSON.stringify(comments)); // Make a deep copy
        const editedDataIndex = datacomments.findIndex((d) => d.comment_id === comment?.comment_id);
        if (editedDataIndex !== -1) {
            // Toggle the isSlice state to show more or less content
            datacomments[editedDataIndex].isSlice = !datacomments[editedDataIndex].isSlice;
        }
        setComments(datacomments); // Update the state with the new list
    };

    const commentCountForClient = (comments) => {
        const count = comments.filter((comment) => comment.is_public);
        return count?.length
    }



    return (
        <div className='pb-2 h-100 d-flex flex-column justify-content-between w-100' style={{}} >
            <div className='comment-content'>
            <div ref={commentsTopRef} />
                <div className=''>
                    <Dialog
                        open={openAlert}
                        onClose={handleCloseDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        className='mention-alert-dialog'
                    >
                        <DialogTitle id="alert-dialog-title" className="text-danger font-noto-sans">
                            Alert
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText
                                id="alert-dialog-description"
                                className='font-noto-sans'
                            >
                                This users, <span className='text-primary'>{newProjectUsers}</span> is not a part of the current project. Would you like to add this user to this project?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleRejection} className='rounded-pill '>No</Button>
                            <Button
                                onClick={handleConfirmation}
                                autoFocus
                                className="background-black text-white"
                            >
                                Yes
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                <div className='bg-color-gray position-sticky top-0 pt-2 pb-1 chatbox-space' style={{ zIndex: "2" }} >
                    <div className='d-flex align-items-center gap-md-4 gap-2 ps-3 border-bottom'>
                        <button className={`font-noto-sans d-flex align-items-center gap-2 fs-16 border-0 px-1 bg-transparent add_navbar ${tab === 1 && "active"}`} onClick={() => setTab(1)}>
                            <img src={commentIcon} alt='comment-Icon' className='image-brightness-05' />
                            {t("comment")}

                            {(userSettings?.role_id === 1 || userSettings?.role_id === 2 || userSettings?.role_id === 3 || userSettings?.role_id === 5) ?
                                (<span>{comments?.length > 0 ? filterData(comments)?.length : '0'}</span>) :
                                (<span>{comments?.length > 0 ? commentCountForClient(filterData(comments)) : '0'}</span>)
                            }

                        </button>
                        <button className={`font-noto-sans d-flex align-items-center gap-2 fs-16 border-0 px-1 bg-transparent add_navbar ${tab === 2 && "active"}`} onClick={() => setTab(2)}>
                            <img src={detailIcon} alt='task-icon' className='image-brightness-05' />
                            {t("details")}  <span>0</span>
                        </button>
                    </div>
                    {tab === 1 &&
                        <div className="d-flex gap-3 mt-3 ms-3 ">
                            <FormControl >
                                <div className='annotate-border comment-select rounded-pill'>
                                    <Select
                                        value={commentStatus}
                                        name="channel"
                                        onChange={handleCommentStatus}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        className="w-100 border-none text-black"
                                    >
                                        <MenuItem value={'1'}>{t("All")}</MenuItem>
                                        <MenuItem value={'2'}>{t("resolvedOnly")}</MenuItem>
                                        <MenuItem value={'3'} >{t("unresolvedOnly")}</MenuItem>
                                    </Select>
                                </div>
                            </FormControl>
                            {/* <FormControl >
                                <div className='annotate-border comment-select rounded-pill'>
                                    <Select

                                        name="channel"

                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        className="w-100 border-none text-black"
                                    >
                                        <MenuItem selected>{t("thisFile")}</MenuItem>
                                        <MenuItem >{t("allfiles")}</MenuItem>
                                    </Select>
                                </div>
                            </FormControl> */}
                            <FormControl >
                                <div className='annotate-border comment-select rounded-pill select-design'>
                                    <Select
                                        value={commentType}
                                        name="channel"
                                        onChange={handleCommentType}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        className="w-100 border-none text-black"
                                    >
                                        <MenuItem value={'1'} >{t("wireframeOnly")}</MenuItem>
                                        <MenuItem value={'2'}>{t("designOnly")}</MenuItem>
                                        <MenuItem value={'3'}>{t("All")}</MenuItem>
                                    </Select>
                                </div>
                            </FormControl>
                            <FormControl >
                                <div className='annotate-border comment-select rounded-pill select-design'>
                                    <Select
                                        value={designId}
                                        name="channel"
                                        onChange={handleDesignId}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        className="w-100 border-none text-black"
                                        disabled={commentType === "1"}
                                    >
                                        <MenuItem value={0}><em>None</em></MenuItem>
                                        {designDetails?.length > 0 &&
                                            designDetails?.map((design) => (
                                                <MenuItem value={design?.design_id} >
                                                    <img src={design?.image_url} alt='design' height={35} width={35}></img>
                                                </MenuItem>
                                            ))}
                                    </Select>

                                </div>
                                {/* <FormHelperText>Design</FormHelperText> */}
                            </FormControl>
                        </div>
                    }
                </div>
                {/* add comment */}

                {comments?.length > 0 ? (
                    <div className='pb-4 position-relative ' >
                        {tab === 1 &&
                            <div className=''>
                                {comments && comments?.length > 0 &&
                                    filterData(comments)?.sort((a, b) => new Date(b.updated_timestamp) - new Date(a.updated_timestamp)).map((comment, i) => {
                                        let publicComment = comment?.is_public
                                        let currentUser = userList.find(user => user.id === comment?.updated_by)


                                        return (
                                            ((userSettings?.role_id === 1 || userSettings?.role_id === 2 || userSettings?.role_id === 3 || userSettings?.role_id === 5)
                                                || ((userSettings?.role_id == 4 || userSettings?.role_id === 6) && publicComment)) ? (
                                                <div className='position-relative pt-1'>
                                                    <div key={i} className='mt-2 py-3 px-xxl-4 px-2 chat-padding mx-2' style={{ backgroundColor: "#edf2fa", borderRadius: "10px" }}>
                                                        <div className='d-flex gap-2 align-items-start justify-content-between chat-align'>
                                                            <div className='d-flex gap-2 align-items-center cursor-pointer' onClick={() => onHandleReplyInputField(comment?.comment_id)}>
                                                                <div className='icon-box-width font-noto-sans  fs-16 text-white d-flex justify-content-center align-items-center position-relative background-black rounded-circle'>
                                                                    <div className="d-flex align-items-center">
                                                                        {/* <p className="mb-0">
                                                                <span>{userSettings?.username?.slice(0, 2)?.toUpperCase()}</span>
                                                            </p> */}
                                                                        <p className='mb-0'>
                                                                            {/* <span>{getCommentedUser(comment?.updated_by)?.charAt(0)?.toUpperCase()}</span> */}
                                                                            <span>{comment?.author_details?.author_user_name?.charAt(0)?.toUpperCase() || comment?.author_details[0]?.author_user_name?.charAt(0)?.toUpperCase()}</span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div className='d-flex  flex-column gap-0 mt-2'>
                                                                        <Tooltip
                                                                            // key={`${id}-${start}`}
                                                                            title={
                                                                                <div>
                                                                                    <strong>{currentUser?.display}</strong>
                                                                                    <br />
                                                                                    Email: {currentUser?.email}
                                                                                </div>
                                                                            }
                                                                            arrow
                                                                            placement="top"
                                                                            PopperProps={{
                                                                                style: { zIndex: 3000 }, // Ensures tooltip appears above other elements
                                                                            }}
                                                                        >
                                                                            <h6 className='font-noto-sans fs-14 text-black mb-0 fw-bold'>
                                                                                {/* {getCommentedUser(comment?.updated_by)} */}
                                                                                {comment?.author_details?.author_user_name || comment?.author_details[0]?.author_user_name}
                                                                            </h6>
                                                                        </Tooltip>

                                                                        <p className='font-noto-sans text-dark-gray fs-12 mb-0'>{comment?.created_timestamp && formatCommentDate2(comment?.created_timestamp)}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='d-flex align-items-center gap-2'>
                                                                {/* {comment.comment &&
                                                        <button className='border-0 bg-transparent'>
                                                            <img src={commenticon} alt='comment-Icon' />
                                                        </button>
                                                    } */}

                                                                {comment?.is_public && (userSettings?.role_id !== 4 || userSettings?.role_id !== 6) ? (
                                                                    <button className='border-0 bg-transparent p-0'>
                                                                        <img src={publicIcon} alt='publicIcon' />
                                                                    </button>
                                                                ) : (null)}

                                                                {
                                                                    !comment?.comment_status && (
                                                                        <Tooltip
                                                                            title="Mark as resolved"
                                                                            slotProps={{
                                                                                popper: {
                                                                                    modifiers: [
                                                                                        {
                                                                                            name: 'offset',
                                                                                            options: {
                                                                                                offset: [0, -8],
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                                },
                                                                            }}
                                                                        >
                                                                            <button className='border-0 bg-transparent p-0' onClick={() => onResolveComment(comment.comment_id, comment)}>
                                                                                <img src={trueIcon} alt='trueIcon' />
                                                                            </button>
                                                                        </Tooltip>

                                                                    )
                                                                }
                                                                {((userSettings?.account_login_id === comment?.author_details?.author_user_id) || (userSettings?.account_login_id === comment?.author_details[0]?.author_user_id)) &&
                                                                    <div className='comment-options'>
                                                                        <Button
                                                                            id="basic-button"
                                                                            aria-controls={menuOpenCommentId === comment.comment_id ? 'basic-menu' : undefined}
                                                                            aria-haspopup="true"
                                                                            aria-expanded={menuOpenCommentId === comment.comment_id ? 'true' : undefined}
                                                                            onClick={(e) => handleClick(e, comment.comment_id)}
                                                                            style={{}}
                                                                            className='border-0'
                                                                        >
                                                                            <img src={ellipsis_vertical} alt='menu' />
                                                                        </Button>
                                                                        <Menu
                                                                            id="basic-menu"
                                                                            anchorEl={menuOpenCommentId === comment.comment_id ? anchorEl : null}
                                                                            open={menuOpenCommentId === comment.comment_id && Boolean(anchorEl)}
                                                                            onClose={handleClose}
                                                                            MenuListProps={{
                                                                                'aria-labelledby': 'basic-button',
                                                                            }}
                                                                        >
                                                                            {!comment?.comment_status &&
                                                                                <MenuItem onClick={() => onUpadateComment(comment.comment_id, comment)}>{t("edit")}</MenuItem>
                                                                            }
                                                                            {/* <MenuItem onClick={() => handleAddComment(comment.comment_id, comment,"edit_comment")}>{t("edit")}</MenuItem> */}
                                                                            <MenuItem onClick={() => onDeleteComment(comment.comment_id, comment)}>{t("delete")}</MenuItem>
                                                                            {((userSettings?.role_id === 1 || userSettings?.role_id === 2 || userSettings?.role_id === 3) && !comment?.comment_status) &&
                                                                                <MenuItem onClick={() => onMakeCommentPublic(comment.comment_id, comment)} disabled={comment?.is_public}>{t("makePublic")}</MenuItem>
                                                                            }
                                                                            {comment?.comment_status ? (
                                                                                <MenuItem onClick={() => onAddReply(comment.comment_id, comment, "Re-opened")}>{t("reOpen")}</MenuItem>
                                                                            ) : (null)
                                                                            }
                                                                        </Menu>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div style={{ position: "relative" }} onMouseEnter={() => handleMouseEnter(comment.comment_id)} onMouseLeave={handleMouseLeave}>
                                                            {/* Display the parsed comment */}
                                                            {/* {isValidURL(comment?.content || comment?.comment_content) ?
                                                                (<a href={comment?.content || comment?.comment_content} target="_blank" rel="noopener noreferrer" style={{ overflowWrap: 'anywhere' }}>{comment?.content || comment?.comment_content}</a>)
                                                                : ( */}
                                                                    <p className='text-black fs-14 font-noto-sans mb-0 fw-normal mt-1 text-break' >
                                                                        {/* {parseMentions(comment?.content || comment?.comment_content, userList)} */}
                                                                        {parseTextWithUrlsAndMentions(comment?.content || comment?.comment_content, userList)}
                                                                    </p>
                                                                {/* )} */}



                                                            {/* {comment?.comment_status ? (
                                                            <p className='text-gray font-noto-sans mb-0 fw-normal mt-1 text-break' style={{ fontSize: "11px" }}>
                                                                Marked as resolved
                                                            </p>
                                                        ) : <></>
                                                        } */}

                                                            {/* Reply and Edit buttons */}
                                                            {hoveredCommentId === comment.comment_id ? (
                                                                <div style={{ position: 'absolute', top: '0', right: '0', display: 'flex', gap: '5px' }}>
                                                                    <button className="hover-btn btn btn-outline-secondary" onClick={() => onHandleReplyInputField(comment?.comment_id)}>{t('reply')}</button>
                                                                </div>
                                                            ) : <></>}
                                                        </div>

                                                        {/* edit comment */}
                                                        {replyActions === comment?.comment_id &&
                                                            <div className='annotate-border w-100 mt-3 bg-transparent px-3'>
                                                                <MentionsInput
                                                                    value={editComment}
                                                                    onChange={onEditCommentChange}
                                                                    // onFocus={() => { setReplyActions(comment?.comment_id); setEditComment(comment?.comment_content) }}
                                                                    placeholder="Edit yout comment"
                                                                    inputRef={mentionInputRef}
                                                                    a11ySuggestionsListLabel={"Suggested mentions"}
                                                                    style={defaultStyleForReply}
                                                                    forceSuggestionsAboveCursor={true}
                                                                    markup='@[__display__](__id__)'
                                                                    maxLength={250}
                                                                    onKeyDown={(e) => handleKeyDown(e, comment.comment_id, comment, "edit_comment")}
                                                                >
                                                                    <Mention
                                                                        data={userList?.length > 0 ? userList : []}
                                                                        // onAdd={onAdd}
                                                                        appendSpaceOnAdd={true}
                                                                        displayTransform={(id, display) => `@${display}`}
                                                                        style={{
                                                                            backgroundColor: '#d0e3ff',
                                                                            borderRadius: '4px',
                                                                        }}
                                                                    />
                                                                </MentionsInput>
                                                            </div>
                                                        }

                                                        {replyActions === comment?.comment_id &&
                                                            <div className='d-flex justify-content-end gap-2 mt-3 align-items-center'>
                                                                <button
                                                                    className='text-nowrap cancel_modal_footer cancel_modal_footer2'
                                                                    onClick={() => { setReplyActions(null); setMentionsUsers([]); setEditCommentData() }}
                                                                >
                                                                    {t("cancel")}
                                                                </button>
                                                                <button
                                                                    className='header-submit rounded-pill text-nowrap header-submit2'
                                                                    // onClick={() => onSubmitEditComment(comment.comment_id, comment)}
                                                                    onClick={() => handleAddComment(comment.comment_id, comment, "edit_comment")}
                                                                    disabled={!editComment.trim()}
                                                                >
                                                                    {t("save")}
                                                                </button>
                                                            </div>
                                                        }

                                                        {/* Open Reply Input Field */}
                                                        {isReplyFieldOpen && replyCommentId === comment?.comment_id &&
                                                            <div className='annotate-border w-100 mt-3 bg-transparent px-3'>
                                                                <MentionsInput
                                                                    value={replyValue}
                                                                    onChange={onChangeReply}
                                                                    placeholder={comment.comment_status ? "Adding a reply will re-open this discussion..." : "Reply or add others with @"}
                                                                    inputRef={addReplyRef}
                                                                    a11ySuggestionsListLabel={"Suggested mentions"}
                                                                    style={defaultStyleForReply}
                                                                    forceSuggestionsAboveCursor={true}
                                                                    markup='@[__display__](__id__)'
                                                                    maxLength={250}
                                                                    onKeyDown={(e) => handleKeyDown(e, comment.comment_id, comment, "reply_comment")}
                                                                >
                                                                    <Mention
                                                                        data={userList?.length > 0 ? userList : []}
                                                                        // onAdd={onAdd}
                                                                        appendSpaceOnAdd={true}
                                                                        displayTransform={(id, display) => `@${display}`}
                                                                        style={{
                                                                            backgroundColor: '#d0e3ff',
                                                                            borderRadius: '4px',
                                                                        }}
                                                                    />
                                                                </MentionsInput>
                                                            </div>
                                                        }

                                                        {isReplyFieldOpen && replyCommentId === comment?.comment_id &&
                                                            <div className='d-flex justify-content-end gap-2 mt-3 align-items-center'>
                                                                <button
                                                                    className='text-nowrap cancel_modal_footer cancel_modal_footer2'
                                                                    onClick={() => { setIsReplyFieldOpen(false); setReplyValue(""); setNewReply(false) }}
                                                                >
                                                                    {t("cancel")}
                                                                </button>
                                                                <button
                                                                    className='header-submit rounded-pill text-nowrap header-submit2'
                                                                    // onClick={() => onAddReply(comment?.comment_id, comment?.mention_user_id)}
                                                                    onClick={() => handleAddComment(comment.comment_id, comment, "reply_comment")}
                                                                    disabled={!replyValue.trim()}
                                                                >
                                                                    {t("save")}
                                                                </button>
                                                            </div>
                                                        }

                                                        <div className='mt-2 '>
                                                            {comment?.replies?.length > 0 &&
                                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}  >
                                                                    <div style={{ flexGrow: 1, height: "1px", backgroundColor: "#D3D3D3" }}></div>
                                                                    {comment?.replies?.length <= 2 ? (
                                                                        // If there are 3 or fewer replies, show the count
                                                                        <>
                                                                            <span className='font-noto-sans chat-replay' style={{ padding: "0 10px", fontSize: "12px", fontWeight: "bold" }}>
                                                                                {comment?.replies?.length} {t("reply")}
                                                                            </span>
                                                                            <div style={{ flexGrow: 1, height: "1px", backgroundColor: "#D3D3D3" }}></div>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {/* When there are more than 3 replies */}
                                                                            {!comment?.isSlice ? (
                                                                                // If isSlice is false, show Read More with the hidden replies count
                                                                                <>
                                                                                    <span
                                                                                        onClick={() => onClickOfReadMore(comment)}
                                                                                        className='font-noto-sans chat-replay cursor-pointer bg-secondary rounded-pill px-2'
                                                                                        style={{ padding: "0 10px", fontSize: "12px", fontWeight: "bold", color: '#fff' }}
                                                                                    >
                                                                                        {`${comment?.replies?.length - 2} ${t("More Replies")}`}
                                                                                    </span>
                                                                                    <div style={{ flexGrow: 1, height: "1px", backgroundColor: "#D3D3D3" }}></div>
                                                                                </>
                                                                            ) : (
                                                                                // If isSlice is true, show Read Less
                                                                                <>
                                                                                    <span
                                                                                        onClick={() => onClickOfReadMore(comment)}
                                                                                        className='font-noto-sans chat-replay cursor-pointer bg-secondary rounded-pill px-2'
                                                                                        style={{ padding: "0 10px", fontSize: "12px", fontWeight: "bold", color: '#fff' }}
                                                                                    >
                                                                                        {t("Read Less")}
                                                                                    </span>
                                                                                    <div style={{ flexGrow: 1, height: "1px", backgroundColor: "#D3D3D3" }}></div>
                                                                                </>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </div>
                                                            }
                                                            {(comment?.isSlice ? comment?.replies : comment?.replies?.slice(-2))?.map((replyItem, i) => {
                                                                let replyUser = userList.find(user => user.id === (replyItem?.author_user_id || replyItem?.reply_author_details?.reply_author_user_id))
                                                                return (
                                                                    <div key={i} className='mb-2 ms-4 position-relative d-flex align-items-center justify-content-center'>

                                                                        <div className='w-100'>
                                                                            <div className='d-flex gap-2 align-items-center chat-replay-wrap'>
                                                                                <div className='icon-box-width font-noto-sans  fs-16 text-white d-flex justify-content-center align-items-center position-relative background-black rounded-circle'>
                                                                                    <div className="d-flex align-items-center">
                                                                                        <p className="mb-0">
                                                                                            <span>{replyItem?.reply_author_details?.reply_author_user_name?.charAt(0)?.toUpperCase() || replyItem?.author_user_name?.charAt(0)?.toUpperCase()}</span>
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                                <div>
                                                                                    <div className='d-flex  flex-column gap-0 mt-2'>
                                                                                        <Tooltip
                                                                                            title={
                                                                                                <div>
                                                                                                    <strong>{replyUser?.display}</strong>
                                                                                                    <br />
                                                                                                    Email: {replyUser?.email}
                                                                                                </div>
                                                                                            }
                                                                                            arrow
                                                                                            placement={i === 0 ? "bottom" : "top"}
                                                                                            PopperProps={{
                                                                                                style: { zIndex: 3000 },
                                                                                            }}
                                                                                        >
                                                                                            <h6 className='font-noto-sans fs-14 text-black mb-0 fw-bold cursor-pointer'>{replyItem?.reply_author_details?.reply_author_user_name || replyItem?.author_user_name}</h6>
                                                                                        </Tooltip>
                                                                                        <p className='font-noto-sans text-dark-gray fs-12 mb-0'>{replyItem?.reply_updated_timestamp && formatCommentDate2(replyItem?.reply_updated_timestamp)}</p>
                                                                                    </div>
                                                                                </div>
                                                                                {(userSettings?.account_login_id === replyItem?.reply_author_details?.reply_author_user_id || userSettings?.account_login_id === replyItem?.author_user_id) &&
                                                                                    <div className='comment-options' style={{ marginLeft: "auto" }}>
                                                                                        <Button
                                                                                            id="basic-button"
                                                                                            aria-controls={menuOpenReplyId === replyItem?.reply_id ? 'basic-menu' : undefined}
                                                                                            aria-haspopup="true"
                                                                                            aria-expanded={menuOpenReplyId === replyItem?.reply_id ? 'true' : undefined}
                                                                                            onClick={(e) => onHandleReplyMenu(e, replyItem?.reply_id)}
                                                                                            style={{}}
                                                                                            className='border-0'
                                                                                        >
                                                                                            <img src={ellipsis_vertical} alt='menu' />
                                                                                        </Button>
                                                                                        <Menu
                                                                                            id="basic-menu"
                                                                                            anchorEl={menuOpenReplyId === replyItem?.reply_id ? anchorElReply : null}
                                                                                            open={menuOpenReplyId === replyItem?.reply_id && Boolean(anchorElReply)}
                                                                                            onClose={onCloseReplyMenu}
                                                                                            MenuListProps={{
                                                                                                'aria-labelledby': 'basic-button',
                                                                                            }}
                                                                                        >
                                                                                            {!comment?.comment_status &&
                                                                                                <MenuItem onClick={() => onUpdateReply(replyItem, comment?.comment_id)}>{t("edit")}</MenuItem>
                                                                                            }
                                                                                            <MenuItem onClick={() => onDeleteReply(replyItem?.reply_id)}>{t("delete")}</MenuItem>
                                                                                        </Menu>
                                                                                    </div>
                                                                                }
                                                                            </div>

                                                                            <p className='text-black fs-14 font-noto-sans mb-0 fw-normal mt-1 chat-replay-content text-break'>
                                                                                {parseTextWithUrlsAndMentions((replyItem?.content || replyItem?.reply_content), userList)} 
                                                                            </p>
                                                                            {/* Open Reply Edit Input Field */}

                                                                            {isReplyFieldOpen && replyCommentId === replyItem?.reply_id &&
                                                                                <div className='annotate-border w-100 mt-3 bg-transparent px-3'>
                                                                                    <MentionsInput
                                                                                        value={replyValue}
                                                                                        onChange={onReplyEditValue}
                                                                                        placeholder="Edit Reply"
                                                                                        inputRef={addReplyRef}
                                                                                        markup='@[__display__](__id__)'
                                                                                        a11ySuggestionsListLabel={"Suggested mentions"}
                                                                                        style={defaultStyleForReply}
                                                                                        maxLength={250}
                                                                                        onKeyDown={(e) => handleKeyDown(e, comment.comment_id, comment, "edit_reply", replyItem)}
                                                                                    >
                                                                                        <Mention
                                                                                            data={userList?.length > 0 ? userList : []}
                                                                                            // onAdd={onAdd}
                                                                                            appendSpaceOnAdd={true}
                                                                                            displayTransform={(id, display) => `@${display}`}
                                                                                            style={{
                                                                                                backgroundColor: '#d0e3ff',
                                                                                                borderRadius: '4px',
                                                                                            }}
                                                                                        />
                                                                                    </MentionsInput>
                                                                                </div>
                                                                            }

                                                                            {isReplyFieldOpen && replyCommentId === replyItem?.reply_id &&
                                                                                <div className='d-flex justify-content-end gap-2 mt-3 align-items-center'>
                                                                                    <button
                                                                                        className='text-nowrap cancel_modal_footer cancel_modal_footer2'
                                                                                        onClick={() => { setIsReplyFieldOpen(false); setReplyValue(""); setEditReply(false) }}
                                                                                    >
                                                                                        {t("cancel")}
                                                                                    </button>
                                                                                    <button
                                                                                        className='header-submit rounded-pill text-nowrap header-submit2'
                                                                                        // onClick={() => onSubmitEditReply(replyItem, comment)}
                                                                                        onClick={() => handleAddComment(comment.comment_id, comment, "edit_reply", replyItem)}
                                                                                        disabled={!replyValue.trim()}
                                                                                    >
                                                                                        {t("save")}
                                                                                    </button>
                                                                                </div>
                                                                            }
                                                                        </div>

                                                                        {replyDeleteAlert === replyItem?.reply_id &&
                                                                            <div className='position-absolute w-100 h-100'>
                                                                                <div className='position-relative p-1 d-flex justify-content-center align-items-center h-100' style={{ backgroundColor: "#212529d4" }}>
                                                                                    <div className='d-flex gap-3 justify-content-center position-absolute' style={{ zIndex: "1" }}>
                                                                                        <button className='bg-white border-0 rounded-pill px-2 py-1' onClick={() => handleReplyDelete(replyItem?.reply_id)}>Delete</button>
                                                                                        <button className='bg-white border-0 rounded-pill px-2 py-1' onClick={() => setReplyDeleteAlert(null)}>Cancel</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        }



                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {commentDeleteAlert === comment?.comment_id &&
                                                            <div className='position-absolute w-100 h-100 top-0'>
                                                                <div className='position-relative p-1 h-100 d-flex justify-content-center' style={{ backgroundColor: "#212529d4" }}>
                                                                    <div className=' position-absolute top-0' style={{ zIndex: "1" }}>
                                                                        <p className='mb-0 py-1 text-white'>Delete this comment thread?</p>
                                                                        <div className='d-flex gap-3 justify-content-center' >
                                                                            <button className='bg-white border-0 rounded-pill px-2 py-1' onClick={() => handleDeleteComment(comment?.comment_id)}>Delete</button>
                                                                            <button className='bg-white border-0 rounded-pill px-2 py-1' onClick={() => setCommentDeleteAlert(null)}>Cancel</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            ) : (null)
                                        )
                                    })
                                }
                            </div>
                        }
                        {tab === 2 &&
                            <div>

                            </div>

                        }
                    </div>
                ) : (
                    <div className='d-flex justify-content-center h-100  align-content-center' >

                    </div>
                )}

                <div ref={commentsEndRef} />
            </div>
            {
                tab === 1 &&
                <div className='add-comment p-1 border-top w-100 position-sticky bottom-0' style={{ zIndex: '1' }}>
                    <div>
                        <MentionsInput
                            value={commentWithoutAnnotate}
                            onChange={onChange}
                            placeholder={t("Annotate&comment")}
                            inputRef={AddCommentRef}
                            a11ySuggestionsListLabel={"Suggested mentions"}
                            style={defaultStyle}
                            forceSuggestionsAboveCursor={true}
                            markup='@[__display__](__id__)'
                            onKeyDown={(e) => handleKeyDown(e, "", "", "new_comment")}
                            maxLength={250}
                        >
                            <Mention
                                data={userList?.length > 0 ? userList : []}
                                // onAdd={onAdd}
                                appendSpaceOnAdd={true}
                                displayTransform={(id, display) => `@${display}`}
                                style={{
                                    backgroundColor: '#d0e3ff',
                                    borderRadius: '4px',
                                }}
                            />
                        </MentionsInput>
                    </div>
                    <div className='d-flex justify-content-end mt-3'>
                        <button className='header-submit rounded-pill text-nowrap'
                            onClick={() => handleAddComment("", "", "new_comment")}
                            disabled={!commentWithoutAnnotate.trim()}>{t("addComment")}</button>
                    </div>
                </div>
            }
        </div>
        // </div>
    );
}

export default CommentAndTask;
